import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {StyledQuickGoogleConnectPageLoading} from './StyledQuickGoogleConnectPage';
import VerifyPageContainer from '../../components/VerifyPageContainer';
import {serviceStatsActions} from '../../state/actions';
import {helpers} from '../../helpers';
import {firebaseEvents} from '../../snippets/firebase';

const {logEvent} = helpers;

const QuickGoogleConnectPage = ({
  generatePublicAuthUrl
}) => {
  const [t] = useTranslation(['quickStart']);
  const trans = (key) => t(`quickStart:quickGoogleConnect.${key}`);

  const [verification, setVerification] = useState({loading: true, success: false});

  const errorUrlGeneration = () => {
    setVerification({loading: false, success: false});
    logEvent(firebaseEvents.QUICK_GOOGLE_CONNECT_GENERATE_URL_ERROR);
  }

  useEffect(() => {
    generatePublicAuthUrl(
      'google',
      (data) => {
        const url = data?.url;
        if (url) {
          logEvent(firebaseEvents.QUICK_GOOGLE_CONNECT_GENERATE_URL_SUCCESS);
          window.open(url, '_self');
        } else {
          errorUrlGeneration();
        }
      },
      errorUrlGeneration
    );
  }, [generatePublicAuthUrl]);

  return (
    <VerifyPageContainer>
      {verification.loading ? (
        <StyledQuickGoogleConnectPageLoading />
      ) : !verification.success && (
        <div className='redirect-description'>
          {trans('errorDescription')}.
        </div>
      )}
    </VerifyPageContainer>
  );
}

const mapDispatchToProps = {
  generatePublicAuthUrl: serviceStatsActions.generatePublicAuthUrl,
}

export default connect(null, mapDispatchToProps)(QuickGoogleConnectPage);

