import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Input} from 'antd';
import {useTranslation} from 'react-i18next';
import {StyledTagsManagement, StyledTag} from './StyledTagsManagement';
import {companyActions} from '../../../../../state/actions';
import {helpers} from '../../../../../helpers';
import {firebaseEvents} from '../../../../../snippets/firebase';

const logEvent = (eventName, tagName) => helpers.logEvent(eventName, {name: tagName});

const TagsManagement = ({
  dispatch,
  getCompanyTags,
  addNewTag,
  deleteTag,
  companyId,
  tags,
  ...rest
}) => {
  const [trans] = useTranslation(['settings', 'main']);
  const t = (key) => trans(`tabs.tagsManagement.${key}`);
  const [tagName, setTagName] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (companyId) getCompanyTags();
  }, [getCompanyTags, companyId]);

  const handleAddTag = () => {
    setLoading(true);
    addNewTag(
      tagName,
      () => {
        getCompanyTags();
        setLoading(false);
        setTagName('');
        logEvent(firebaseEvents.COMPANY_SETTINGS_ADD_TAG, tagName);
      },
      () => setLoading(false)
    )
  }

  const handleDeleteTag = (tag) => {
    deleteTag(
      tag.tag_id,
      () => {
        getCompanyTags();
        logEvent(firebaseEvents.COMPANY_SETTINGS_DELETE_TAG, tag.tag_name);
      }
    );
  }

  const onChange = (e) => setTagName(e.target.value);

  const onKeyDown = (e) => {
    if (e.keyCode === 13 && tagName) handleAddTag();
  }

  return (
    <StyledTagsManagement
      title={t('title')}
      {...rest}
    >
      <p>{t('description')}</p>
      <Input
        disabled={loading}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={t('placeholder')}
        value={tagName}
        size='large'
      />
      <div className='tags-list'>
        {tags.map(tag => (
          <StyledTag
            closable
            key={tag.tag_id}
            onClose={() => handleDeleteTag(tag)}
          >
            {tag.tag_name}
          </StyledTag>
        ))}
      </div>
    </StyledTagsManagement>
  );
}

const mapStateToProps = state => {
  const {tags, id} = state.company;
  return {
    tags,
    companyId: id
  }
}

const mapDispatchToProps = {
  getCompanyTags: companyActions.getTags,
  addNewTag: companyActions.addNewTag,
  deleteTag: companyActions.deleteTag
}

export default connect(mapStateToProps, mapDispatchToProps)(TagsManagement);
