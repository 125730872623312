import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {EditFilled} from '@ant-design/icons';
import {Button, Form, Input, Space} from 'antd';
import {UnmountClosed} from 'react-collapse';
import {EmailRegexp, helpers} from '../../../../../helpers';
import ChangePasswordModal from '../../ChangePasswordModal';
import VerifyModal from '../../../../quickStart/VerifyModal/VerifyModal';
import {userActions} from '../../../../../state/actions';
import useContentLoaded from '../../../../../hooks/useContentLoaded';
import {StyledPersonalInfo, StyledPasswordItem, StyledTitle} from './StyledPersonalInfo';
import UserInfo from '../../tabComponents/UserInfo';
import DetailsTable from '../../../TransactionsPage/DetailsTable';
import EditableAvatar from '../../../../EditableAvatar';
import SpinSmall from '../../../../SpinSmall';
import {firebaseEvents} from '../../../../../snippets/firebase';

const gOP = helpers.getObjProp;

const {Item} = Form;

const PersonalInfo = ({
  dispatch,
  settings,
  getSettings,
  updateEmployeeInformation,
  changeEmployeePassword,
  getUserProfile,
  sendEmailVerification,
  ...rest
}) => {
  const isLoaded = useContentLoaded(settings);
  const [isEditMode, setIsEditMode] = useState(false);
  const [logo, setLogo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    first_name: '',
    email: '',
    last_name: '',
    phone_number: ''
  });
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [verificationLoading, setVerificationLoading] = useState(false);
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState(undefined);

  useEffect(() => getSettings(), [getSettings]);

  useEffect(() => clearError(), [isEditMode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!helpers.isEmptyObject(settings)) {
      const {logo} = settings;
      const formValues = {
        first_name: settings.first_name,
        email: settings.email,
        last_name: settings.last_name,
        logo,
        phone_number: settings.phone_number,
      }
      setFormValues(formValues);
      form.setFieldsValue(formValues);
      setLogo(logo);
    }
  }, [settings]); // eslint-disable-line react-hooks/exhaustive-deps

  const [t] = useTranslation('main');

  const lengthFieldMessage = (value, type = 'min') => t(`validation.${type}LengthValidation`, {value});

  const requiredRules = [{required: true, message: t('validation.fieldIsRequired')}];

  const showContent = (content, loader) => helpers.showContentWithLoader(isLoaded, content, loader);

  const email = gOP(settings, 'email');
  const name = gOP(settings, 'full_name');
  const phoneNumber = helpers.getFormattedPhoneNumber(gOP(settings, 'phone_number'));

  const handleVerify = () => {
    setVerificationLoading(true);
    sendEmailVerification(
      email,
      () => {
        setShowVerifyModal(true);
        setVerificationLoading(false);
      },
      () => setVerificationLoading(false)
    );
  }

  const clearError = () => errorMessage && setErrorMessage(undefined);

  const handleChangeLogo = ({file}) => {
    setLogo(file);
    clearError();
  }

  const onEditClick = () => setIsEditMode(true);

  const handleOnCancel = () => setIsEditMode(false);

  const handleOnSave = () => form.submit();

  const handleCancelVerifyModal = () => setShowVerifyModal(false);

  const handleCancelPasswordModal = () => setShowPasswordModal(false);

  const handleOkPasswordModal = (data, successCallback, errorCallback) => {
    changeEmployeePassword(
      data,
      () => {
        setShowPasswordModal(false);
        successCallback && successCallback();
      },
      (resp) => errorCallback && errorCallback(resp)
    );
  }

  const onChangePasswordClick = () => setShowPasswordModal(true);

  const handleSubmit = (fields) => {
    let data = {}

    Object.keys(fields).forEach(key => {
      const fieldValue = fields[key];
      if (fieldValue !== formValues[key]) data = {...data, [key]: fieldValue};
    });

    // update or remove company logo
    if (formValues.logo !== logo) {
      data = {
        ...data,
        logo: logo ? helpers.cutFileData(logo) : null
      }
    }

    if (Object.keys(data).length === 0) {
      setIsEditMode(false);
    } else {
      setLoading(true);
      updateEmployeeInformation(
        data,
        () => {
          getSettings(() => {
            setIsEditMode(false);
            setLoading(false);
          });
          getUserProfile();
          helpers.logEvent(firebaseEvents.PROFILE_SETTINGS_EDIT_DETAILS);
        },
        (error) => {
          let fields = helpers.getFormServerErrorFields(error);
          form.setFields(fields);
          setLoading(false);
        }
      );
    }
  }

  const isVerifiedEmail = gOP(settings, 'email_verified', false) || false;

  const tableData = [
    {
      key: 'name',
      label: t('name'),
      value: showContent(name)
    },
    {
      key: 'email',
      label: t('email'),
      value: (
        <Space size='middle'>
          {showContent(email)}
          {isLoaded && !isVerifiedEmail && (
            <Button
              loading={verificationLoading}
              onClick={handleVerify}
              size='middle'
              type='primary'
            >
              {t('verify')} {t('email')}
            </Button>
          )}
        </Space>
      )
    },
    {
      key: 'phone-number',
      label: t('phoneNumber'),
      value: showContent(phoneNumber)
    },
    {
      key: 'password',
      label: t('password'),
      value: showContent(
        <Space size='middle'>
          {[...Array(6).keys()].map(key => <StyledPasswordItem key={key} />)}
          <Button
            onClick={onChangePasswordClick}
            type='default'
          >
            {t('change')} {t('password')}
          </Button>
        </Space>
      )
    }
  ];

  const title = isEditMode ? (
    <>
      <StyledTitle>
        <EditableAvatar
          disabled={loading}
          defaultValue={formValues.logo}
          initials={helpers.getInitials(name)}
          onChange={handleChangeLogo}
          onError={setErrorMessage}
          showError={false}
        />
        <Space size='middle'>
          <Button
            disabled={loading}
            onClick={handleOnCancel}
            size='large'
            type='default'
          >
            {t('cancel')}
          </Button>
          <Button
            disabled={loading}
            onClick={handleOnSave}
            size='large'
            type='primary'
          >
            {t('save')}
          </Button>
        </Space>
      </StyledTitle>
      <UnmountClosed isOpened={Boolean(errorMessage)}>
        <span className='title-error-message'>
          {errorMessage}
        </span>
      </UnmountClosed>
    </>
  ) : (
    <StyledTitle>
      <UserInfo className='user-info' />
      <Button
        icon={<EditFilled />}
        onClick={onEditClick}
        size='large'
        type='default'
      >
        {t('edit')}
      </Button>
    </StyledTitle>
  );

  return (
    <StyledPersonalInfo
      title={title}
      {...rest}
    >
      <DetailsTable
        className={isEditMode && 'd-none'}
        data={tableData}
      />

      <SpinSmall spinning={loading}>
        <Form
          className={!isEditMode && 'd-none'}
          initialValues={formValues}
          form={form}
          layout='vertical'
          onFinish={handleSubmit}
        >
          <Item
            label={t('firstName')}
            name='first_name'
            rules={[
              ...requiredRules,
              {min: 2, message: lengthFieldMessage(2, 'min')},
              {max: 150, message: lengthFieldMessage(150, 'max')}
            ]}
            required
          >
            <Input
              minLength={2}
              maxLength={150}
              size='large'
            />
          </Item>
          <Item
            label={t('secondName')}
            name='last_name'
            rules={[
              ...requiredRules,
              {min: 2, message: lengthFieldMessage(2, 'min')},
              {max: 150, message: lengthFieldMessage(150, 'max')}
            ]}
            required
          >
            <Input
              minLength={2}
              maxLength={150}
              size='large'
            />
          </Item>
          <Item
            label={t('phoneNumber')}
            name='phone_number'
            required
          >
            <Input
              disabled
              size='large'
            />
          </Item>
          <Item
            label={t('email')}
            name='email'
            rules={[
              ...requiredRules,
              {pattern: EmailRegexp, message: t('validation.enterValidEmail')}
            ]}
            required
          >
            <Input
              size='large'
              type='email'
            />
          </Item>
        </Form>
      </SpinSmall>

      <VerifyModal
        open={showVerifyModal}
        email={email}
        onCancel={handleCancelVerifyModal}
      />

      <ChangePasswordModal
        open={showPasswordModal}
        handleOk={handleOkPasswordModal}
        handleCancel={handleCancelPasswordModal}
      />
    </StyledPersonalInfo>
  );
}

const mapStateToProps = state => {
  let {settings} = state.user;

  return {
    settings
  }
}

const mapDispatchToProps = {
  getSettings: userActions.getEmployeeSettings,
  changeEmployeePassword: userActions.changeEmployeePassword,
  updateEmployeeInformation: userActions.updateEmployeeInformation,
  getUserProfile: () => userActions.getUserProfile(false, false),
  sendEmailVerification: userActions.sendEmailVerification
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo);
