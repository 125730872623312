import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Modal, Space} from 'antd';
import {useLocation, useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';
import {ExclamationCircleFilled, PlusOutlined} from '@ant-design/icons';
import PropTypes from 'prop-types';
import {Transition} from 'react-transition-group';
import {
  StyledInvoiceDetailsPanel,
  StyledInvoiceTabsContainer,
  StyledInvoiceTabsContent
} from './StyledInvoiceTabs';
import {StyledTableFiltersDivider} from '../../TransactionsPage/TableFilters/StyledTableFilters';
import UploadInvoicesModal from '../UploadInvoicesModal';
import TabTableMultipleSelection from '../../../TabTableMultipleSelection';
import Empty from '../../../Empty';
import Tabs from '../../../Tabs';
import SpinSmall from '../../../SpinSmall';
import AddTransactionModal from '../AddTransactionModal';
import InvoiceDetails from '../tabComponents/InvoiceDetails';
import InvoicesTab from '../tabs/InvoicesTab';
import ExportModal from '../../TransactionsPage/ExportModal';
import {invoiceStatusConstants} from '../../../../constants';
import {helpers} from '../../../../helpers';
import {invoicesActions} from '../../../../state/actions';
import {transactionsHelpers} from '../../TransactionsPage/transactionsHelpers';
import {tabHelpers} from '../../../../tabHelpers';
import {firebaseEvents} from '../../../../snippets/firebase';


const {MATCHED: MATCHED_STATUS} = invoiceStatusConstants;

const tabKeys = {
  ALL: 'ALL',
  MATCHED: 'MATCHED',
  UNMATCHED: 'UNMATCHED'
};

const {ALL, MATCHED, UNMATCHED} = tabKeys;

const perPagePagination = 25;
const duration = 300;
const {details: detailsStyles, table: tableStyles} = transactionsHelpers.getAnimationStyles({duration});

const defaultTableData = {
  loaded: false,
  loadedPages: 1,
  pagination: null,
  page: 1,
  rows: []
}

const defaultQuery = {
  per_page: perPagePagination,
  sort_by: 'date',
  sort_order: 'desc'
}

const defaultModalProps = {
  loading: false,
  open: false
}

const defaultTabKey = tabKeys.ALL.toLowerCase();

const tableElementId = 'invoices-page-table-content';

const {logEvent} = helpers;

const InvoiceTabs = ({
  dispatch,
  batchCreateInvoices,
  batchDeleteInvoices,
  getInvoice,
  getInvoices,
  downloadInvoice,
  downloadZipInvoices,
  linkExpense,
  updateInvoice,
  handleUpdateTotal,
  total,
  totalLoading,
  ...rest
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [t] = useTranslation(['main', 'invoices']);
  const [activeTabKey, setActiveTabKey] = useState(helpers.getHash(location) || defaultTabKey);
  const [tableData, setTableData] = useState({
    [ALL]: defaultTableData,
    [MATCHED]: defaultTableData,
    [UNMATCHED]: defaultTableData
  });
  const [queries, setQueries] = useState({
    [ALL]: defaultQuery,
    [MATCHED]: defaultQuery,
    [UNMATCHED]: defaultQuery
  });
  const [loading, setLoading] = useState({
    [ALL]: false,
    [MATCHED]: false,
    [UNMATCHED]: false
  });
  const [rowsSelected, setRowsSelected] = useState({
    [ALL]: [],
    [MATCHED]: [],
    [UNMATCHED]: []
  });
  const [requiredUpdate, setRequiredUpdate] = useState({
    [ALL]: true,
    [MATCHED]: true,
    [UNMATCHED]: true
  });
  const [addTransactionModalProps, setAddTransactionModalProps] = useState(defaultModalProps);
  const [uploadInvoicesModalProps, setUploadInvoicesModalProps] = useState(defaultModalProps);
  const [exportModalProps, setExportModalProps] = useState(defaultModalProps);
  const [invoiceDetailsProps, setInvoiceDetailsProps] = useState({loading: false});
  const [isOpenDetails, setIsOpenDetails] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [isFixedDetailsWindow, setIsFixedDetailsWindow] = useState(false);
  const [detailsWindowFixedHeight, setDetailsWindowFixedHeight] = useState(0);
  const [selectedRow, setSelectedRow] = useState({tabKey: undefined, index: undefined});

  const tabsNodeRef = useRef(null);
  const detailsNodeRef = useRef(null);

  const trans = (key) => t(`invoices:${key}`);

  const emptyT = (key) => trans(`empty.${key}`);

  useEffect(() => {
    loadData({
      initial: true,
      view: tabKeys[activeTabKey.toUpperCase()]
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let offsetTop = 120;
    if (offsetTop !== detailsWindowFixedHeight) setDetailsWindowFixedHeight(offsetTop);
  }, [isFixedDetailsWindow]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isOpenDetails && !isFixedDetailsWindow) {
      const appPageContainer = document.querySelector('#app-page-container');
      if (appPageContainer && appPageContainer.scrollTop > detailsWindowFixedHeight) setIsFixedDetailsWindow(true);
    }
  }, [isOpenDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    transactionsHelpers.addFixedDetailsWindowScrollEventListener({
      detailsWindowFixedHeight, isOpenDetails, isFixedDetailsWindow, setIsFixedDetailsWindow
    });
  }, [isOpenDetails, isFixedDetailsWindow, detailsWindowFixedHeight]);

  useEffect(() => {
    logEvent(firebaseEvents.INVOICES_ACTIVE_TAB, {name: activeTabKey});
  }, [activeTabKey]);

  const getLabel = (key) => tabHelpers.getLabel({key, total, t: trans});

  const getQuery = (query, view) => {
    let additionalQuery = {};
    if (view === MATCHED) {
      additionalQuery = {...additionalQuery, status_eq: MATCHED_STATUS}
    } else if (view === UNMATCHED) {
      additionalQuery = {...additionalQuery, status_not_eq: MATCHED_STATUS}
    }
    return {
      ...query,
      ...additionalQuery
    }
  }

  const finishInvoiceDetailsLoading = () => setInvoiceDetailsProps({...invoiceDetailsProps, loading: false});

  const loadData = ({view, query = null, initial = false, updateRequired = true}) => {
    const viewQuery = tabHelpers.getViewQuery({initial, query, queries, view});
    setQueries({
      ...queries,
      [view]: viewQuery
    });

    const finishTableLoading = () => {
      setLoading({...loading, [view]: false});
      updateRequired && setRequiredUpdate({...requiredUpdate, [view]: false});
    }

    setLoading({...loading, [view]: true});

    const queryData = getQuery(viewQuery, view);

    getInvoices(
      queryData,
      (response) => {
        tabHelpers.handleStoreTableData({tableData, setTableData, view, response, initial, rowsKey: 'invoices'});
        finishTableLoading();
      },
      finishTableLoading
    );
  }

  const updateTotal = () => handleUpdateTotal && handleUpdateTotal();

  const handleOnPaginate = (view, activePage) =>
    tabHelpers.handleOnPaginate({view, activePage, tableData, setTableData, loadDataFunc: loadData})

  const handleOnFilter = (view, filterQuery) =>
    tabHelpers.handleOnFilter({view, filterQuery, loadDataFunc: loadData, queries, setQueries});

  const handleOnTableChange = (view, pagination, filters, sorter, extra) => {
    const {action} = extra;
    if (action === 'sort') {
      const {order, field} = sorter;
      loadData({
        initial: true,
        view,
        query: {
          sort_by: field,
          sort_order: order === 'descend' ? 'desc' : 'asc'
        }
      });
    }
  }

  const handleOnRowsSelect = (view, value) => {
    setRowsSelected({
      ...rowsSelected,
      [view]: value
    });
  }

  const handleOnDeleteRow = ({e, view, id}) => {
    e.stopPropagation();
    Modal.confirm({
      title: trans('modal.deleteInvoice.title'),
      icon: <ExclamationCircleFilled />,
      content: trans('modal.deleteInvoice.description'),
      cancelButtonProps: {size: 'large'},
      okButtonProps: {size: 'large'},
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk() {
        if (tableData.hasOwnProperty(view)) {
          const viewData = tableData[view];
          setLoading({...loading, [view]: true});
          batchDeleteInvoices(
            [id],
            () => {
              setTableData({
                ...tableData,
                [view]: {
                  ...viewData,
                  rows: [...viewData.rows].filter(r => r.id !== id)
                }
              });
              setLoading({...loading, [view]: false});
              updateTotal();
              logEvent(firebaseEvents.INVOICES_DELETE_INVOICE);
            },
            () => setLoading({...loading, [view]: false})
          )
        }
      }
    });
  }

  const handleOnMatchClick = ({e, invoice}) => {
    e.stopPropagation();
    loadInvoiceDetails(invoice);
    setAddTransactionModalProps({...addTransactionModalProps, open: true});
  }

  const handleOnAddClick = () => setUploadInvoicesModalProps({...uploadInvoicesModalProps, open: true});

  const handleOnExportClick = () => setExportModalProps({...exportModalProps, open: true});

  const getTableDataValue = (view, key, defaultValue) => tableData.hasOwnProperty(view) ? tableData[view][key] : defaultValue;

  const getTabProps = (key, totalKey) => {
    const data = getTableDataValue(key, 'rows', []);
    return {
      data,
      filtersRightSideContent: (
        <Space size={0}>
          <Button
            onClick={handleOnExportClick}
            type='default'
            size='large'
          >
            {t('export')}
          </Button>
          <StyledTableFiltersDivider type='vertical' />
          <Button
            icon={<PlusOutlined />}
            onClick={handleOnAddClick}
            type='primary'
            size='large'
          >
            {t('invoice')}
          </Button>
        </Space>
      ),
      onDeleteRow: (e, id) => handleOnDeleteRow({e, view: key, id}),
      onMatchClick: (e, invoice) => handleOnMatchClick({e, invoice}),
      onFilter: (value) => handleOnFilter(key, value),
      openDetails: isOpenDetails,
      tableProps: {
        loading: loading[key] || false,
        onChange: (pagination, filters, sorter, extra) => handleOnTableChange(key, pagination, filters, sorter, extra),
        onRow: (record, index) => ({
          onClick: (e) => handleOnRowClick(e, record, index)
        }),
        pagination: tabHelpers.getTablePagination({handleOnPaginate, tableData, total, totalKey, view: key, perPagePagination}),
        rowClassName: (record, index) => (key.toLowerCase() === selectedRow.tabKey && index === selectedRow.index) && 'selected-row',
        rowSelection: {
          selectedRowKeys: rowsSelected[key],
          onChange: (rows) => handleOnRowsSelect(key, rows)
        }
      }
    }
  }

  const getTabs = () => {
    return [
      {
        key: 'all',
        label: getLabel('all'),
        children: (
          <InvoicesTab {...getTabProps(ALL, 'all')} />
        )
      },
      {
        key: 'matched',
        label: getLabel('matched'),
        children: (
          <InvoicesTab {...getTabProps(MATCHED, 'matched')} />
        )
      },
      {
        key: 'unmatched',
        label: getLabel('unmatched'),
        children: (
          <InvoicesTab {...getTabProps(UNMATCHED, 'unmatched')} />
        )
      }
    ]
  }

  const onChangeTab = (key) => {
    const view = tabKeys[key.toUpperCase()];
    updateTab(key);

    if (!getTableDataValue(view, 'loaded', false) || requiredUpdate[view]) loadData({view, initial: true});
  }

  const clearUpdateState = (view) => {
    setRequiredUpdate({
      ...requiredUpdate,
      [ALL]: true,
      [MATCHED]: true,
      [UNMATCHED]: true,
      [view]: false
    });
  }

  const handleCancelUploadInvoicesModal = () => setUploadInvoicesModalProps({...uploadInvoicesModalProps, ...defaultModalProps});

  const handleOkUploadInvoicesModal = (invoices, successCallback, errorCallback) => {
    setUploadInvoicesModalProps({...uploadInvoicesModalProps, loading: true});
    batchCreateInvoices(
      {invoices},
      () => {
        updateTab(defaultTabKey);
        loadData({view: ALL, initial: true, updateRequired: false});
        updateTotal();
        clearUpdateState(ALL);
        handleCancelUploadInvoicesModal();
        logEvent(firebaseEvents.INVOICES_ADD_NEW);
      },
      (resp) => {
        errorCallback && errorCallback(resp);
        setUploadInvoicesModalProps({...uploadInvoicesModalProps, loading: false});
      }
    )
  }

  const handleCancelAddTransactionModal = () => setAddTransactionModalProps({...addTransactionModalProps, ...defaultModalProps});

  const handleOkAddTransactionModal = (expenseId, successCallback, errorCallback) => {
    if (selectedInvoice === null) return;
    const {id} = selectedInvoice;
    setAddTransactionModalProps({...addTransactionModalProps, loading: true});
    isOpenDetails && setInvoiceDetailsProps({...invoiceDetailsProps, loading: true});
    linkExpense(
      id,
      {expense_id: expenseId},
      () => {
        loadData({view: ALL, initial: true});
        // update invoice if details is opened
        if (isOpenDetails) {
          getInvoice(
            id,
            (data) => {
              setSelectedInvoice(data);
              finishInvoiceDetailsLoading();
            },
            finishInvoiceDetailsLoading
          );
        }
        handleCancelAddTransactionModal();
        logEvent(firebaseEvents.INVOICES_EDIT_INVOICE_TRANSACTION);
      },
      (resp) => {
        errorCallback && errorCallback(resp);
        setAddTransactionModalProps({...addTransactionModalProps, loading: false});
        isOpenDetails && finishInvoiceDetailsLoading();
      }
    )
  }

  const updateTab = (key) => {
    navigate(`${location.pathname}#${key}`);
    setActiveTabKey(key);
  }

  const getSelectedItem = () => Object.keys(rowsSelected).find(key => rowsSelected[key].length > 0) || undefined;

  const getSelectedItemsCount = () => {
    const item = getSelectedItem();
    return item ? rowsSelected[item].length : 0;
  }

  const handleCancelSelected = () => {
    const selectedRows = {};
    Object.keys(rowsSelected).forEach(key => {
      selectedRows[key] = [];
    });
    setRowsSelected(selectedRows);
  }

  const handleDeleteSelected = () => {
    Modal.confirm({
      title: trans('modal.deleteInvoices.title'),
      icon: <ExclamationCircleFilled />,
      content: trans('modal.deleteInvoices.description'),
      cancelButtonProps: {size: 'large'},
      okButtonProps: {size: 'large'},
      okText: t('yes'),
      okType: 'danger',
      cancelText: t('no'),
      onOk() {
        const item = getSelectedItem();
        if (item) {
          setLoading({...loading, [item]: true});
          batchDeleteInvoices(
            rowsSelected[item],
            () => {
              handleCancelSelected();
              loadData({view: item, initial: true});
              clearUpdateState(item);
              updateTotal();
              logEvent(firebaseEvents.INVOICES_DELETE_INVOICE);
            },
            () => setLoading({...loading, [item]: false})
          )
        } else {
          handleCancelSelected();
        }
      }
    });
  }

  const handleCloseDetails = () => {
    setIsOpenDetails(false);
    setSelectedRow({index: undefined, tabKey: undefined});
  }

  const handleOnRowClick = (e, record, index) => {
    loadInvoiceDetails(record);
    setIsOpenDetails(true);
    setSelectedRow({index, tabKey: activeTabKey});
  }

  const handleOnInvoiceAdd = () => setAddTransactionModalProps({...addTransactionModalProps, open: true});

  const handleOnInvoiceEdit = (data, successCallback, errorCallback) => {
    if (selectedInvoice === null) return;
    const {id} = selectedInvoice;
    updateInvoice(
      id,
      data,
      (invoice) => {
        const view = tabKeys[activeTabKey.toUpperCase()];
        const viewData = tableData[view];
        // update invoices in selected tab
        setTableData({
          ...tableData,
          [view]: {
            ...viewData,
            rows: [...viewData.rows].map(row => row.id === id ? invoice : row)
          }
        });
        // update selected invoice
        setSelectedInvoice({...selectedInvoice, ...invoice});
        successCallback && successCallback(invoice);
        logEvent(firebaseEvents.INVOICES_EDIT_INVOICE_DETAILS);
      },
      (resp) => errorCallback && errorCallback(resp)
    )
  }

  const handleOnInvoiceDownload = (successCallback, errorCallback) => {
    if (selectedInvoice === null) return;
    const {id} = selectedInvoice;
    setInvoiceDetailsProps({...invoiceDetailsProps, loading: true});
    downloadInvoice(
      id,
      (data) => {
        helpers.saveFile(data);
        setInvoiceDetailsProps({...invoiceDetailsProps, loading: false});
        successCallback && successCallback(data);
        logEvent(firebaseEvents.INVOICES_DOWNLOAD_INVOICE);
      },
      (resp) => {
        setInvoiceDetailsProps({...invoiceDetailsProps, loading: false});
        errorCallback && errorCallback(resp);
      }
    )
  }

  const loadInvoiceDetails = (invoice) => {
    const {id} = invoice;
    if (selectedInvoice && id === selectedInvoice.id) return;
    setSelectedInvoice(invoice);
    getInvoice(
      id,
      (data) => setSelectedInvoice(data)
    );
  }

  const handleCancelExportModal = () => setExportModalProps({...exportModalProps, open: false, loading: false});

  const handleOkExport = (data) => {
    const date = data.date;
    const query = {
      year_month: date.format('YYYY-MM'),
    };
    setExportModalProps({...exportModalProps, loading: true});
    downloadZipInvoices(
      query,
      (data) => {
        window.open(data.url, 'download');
        handleCancelExportModal();
        logEvent(firebaseEvents.INVOICES_EXPORT);
      },
      () => setExportModalProps({...exportModalProps, open: true, loading: false})
    )
  }

  const tabs = getTabs();

  const isExistsInvoices = total && total.all > 0;
  const selectedItemsCount = getSelectedItemsCount();
  const hasSelected = selectedItemsCount > 0;

  return (
    <>
      {!isExistsInvoices && !totalLoading && (
        <Empty
          description={emptyT('description')}
          title={emptyT('title')}
        >
          <Button
            onClick={handleOnAddClick}
            size='large'
            type='primary'
          >
            {trans('uploadInvoice')}
          </Button>
        </Empty>
      )}
      {isExistsInvoices && (
        <StyledInvoiceTabsContainer opened={isOpenDetails}>
          <Transition nodeRef={tabsNodeRef} in={isOpenDetails} timeout={duration}>
            {state => (
              <StyledInvoiceTabsContent
                id={tableElementId}
                style={{
                  ...tableStyles.default,
                  ...tableStyles[state]
                }}
              >
                {hasSelected && (
                  <TabTableMultipleSelection
                    actions={[
                      {onClick: handleDeleteSelected, label: t('delete')},
                      {onClick: handleCancelSelected, label: t('cancel')}
                    ]}
                    count={selectedItemsCount}
                    onCancel={handleCancelSelected}
                  />
                )}
                <Tabs
                  {...rest}
                  activeKey={activeTabKey}
                  items={tabs}
                  onChange={onChangeTab}
                  type='card'
                />
              </StyledInvoiceTabsContent>
            )}
          </Transition>
          <Transition nodeRef={detailsNodeRef} in={isOpenDetails} timeout={duration}>
            {state => (
              <StyledInvoiceDetailsPanel
                onBack={handleCloseDetails}
                fixed={isFixedDetailsWindow}
                hiddenOverflow={!isOpenDetails}
                style={{
                  ...detailsStyles.default,
                  ...detailsStyles[state],
                }}
              >
                <SpinSmall spinning={invoiceDetailsProps.loading}>
                  <InvoiceDetails
                    invoice={selectedInvoice}
                    onAdd={handleOnInvoiceAdd}
                    onDownload={handleOnInvoiceDownload}
                    onEdit={handleOnInvoiceEdit}
                  />
                </SpinSmall>
              </StyledInvoiceDetailsPanel>
            )}
          </Transition>
        </StyledInvoiceTabsContainer>
      )}

      <UploadInvoicesModal
        {...uploadInvoicesModalProps}
        onCancel={handleCancelUploadInvoicesModal}
        onOk={handleOkUploadInvoicesModal}
      />

      <AddTransactionModal
        {...addTransactionModalProps}
        invoice={selectedInvoice}
        onCancel={handleCancelAddTransactionModal}
        onOk={handleOkAddTransactionModal}
      />

      <ExportModal
        {...exportModalProps}
        enableFormatField={false}
        handleCancel={handleCancelExportModal}
        handleOk={handleOkExport}
        title={`${t('export')} ${t('invoices')}`}
      />

    </>
  );
}

InvoiceTabs.propTypes = {
  handleUpdateTotal: PropTypes.func,
  total: PropTypes.shape({
    all: PropTypes.number,
    matched: PropTypes.number,
    unmatched: PropTypes.number,
  }),
  totalLoading: PropTypes.bool
}

InvoiceTabs.defaultProps = {
  totalLoading: false
}

const mapDispatchToProps = {
  getInvoice: invoicesActions.getInvoice,
  getInvoices: invoicesActions.getInvoices,
  batchCreateInvoices: invoicesActions.batchCreateInvoices,
  batchDeleteInvoices: invoicesActions.batchDeleteInvoices,
  downloadInvoice: invoicesActions.downloadInvoice,
  downloadZipInvoices: invoicesActions.downloadZipInvoices,
  linkExpense: invoicesActions.linkExpense,
  updateInvoice: invoicesActions.updateInvoice
}

export default connect(null, mapDispatchToProps)(InvoiceTabs);
