import styled from 'styled-components';
import {Space} from 'antd';
import {borders} from '../../utils/css';

const StyledCardsPageContainer = styled.div`
  display: flex;
  padding: 24px;
  justify-content: center;
  overflow-x: hidden;
  
  .cards-page-empty {
    .ant-empty-description {
      max-width: 380px;
      p {
        margin-bottom: 24px;
      }
    }
  }
`;

const StyledCardsPageContent = styled(Space)`
  ${borders.light};
  background-color: ${props => props.theme.colors.neutral_1};
  padding: 24px 24px 54px 24px;
  min-height: calc(100vh - 80px - 64px);
  overflow-x: auto;
  width: 100%;
`;


const StyledCardsPageTableRowPlaceholder = styled.div`
  color: ${props => props.theme.colors.neutral_7};
  font-size: 12px;
  line-height: 12px;
`;

export {
  StyledCardsPageContainer,
  StyledCardsPageContent,
  StyledCardsPageTableRowPlaceholder
};
