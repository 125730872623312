import React, {useEffect, useMemo, useState} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {UnmountClosed} from 'react-collapse';
import {Select, Space} from 'antd';
import dayjs from 'dayjs';
import BalancePageHeader from '../../components/pages/TransactionsPage/BalancePageHeader';
import PageDocumentDetails from '../../components/PageDocumentDetails';
import CompleteKycCard from '../../components/pages/OverviewPage/CompleteKycCard';
import {
  StyledOverviewPageContainer,
  StyledOverviewPageContent,
  StyledOverviewPageSpin,
  StyledOverviewPageCard,
  StyledOverviewPageCardRightSideSpace,
  StyledOverviewPageSpendingPlaceholder
} from './StyledOverviewPage';
import useIsEmployeeChanged from '../../hooks/useIsEmployeeChanged';
import {companyActions, overviewActions} from '../../state/actions';
import {helpers} from '../../helpers';
import {kycHelpers} from '../../components/quickStart/components/KYCModal/kycHelpers';
import SpendingDetails from '../../components/pages/OverviewPage/SpendingDetails';
import SubscriptionsDetails from '../../components/pages/OverviewPage/SubscriptionsDetails';
import Spending from '../../components/pages/OverviewPage/Spending';
import {subscriptionsHelpers} from '../../components/pages/SubscriptionsPage/subscriptionsHelpers';
import OverviewDropdown from '../../components/pages/OverviewPage/OverviewDropdown';
import {ReactComponent as GraphPlaceholder} from '../../static/images/pages/overview/graph-placeholder.svg';
import {firebaseEvents} from '../../snippets/firebase';

const counterDefaultProps = {value: null, isLoaded: false};

const {month, year} = helpers.getCurrentDate();

const dateFilterRanges = {
  CURRENT_MONTH: 'current-month',
  PREVIOUS_MONTH: 'previous-month',
  YEARLY: 'yearly'
}

const {CURRENT_MONTH, PREVIOUS_MONTH, YEARLY} = dateFilterRanges;

const getRangeDate = (value) => {
  const methods = {
    [CURRENT_MONTH]: () => ({month, year}),
    [PREVIOUS_MONTH]: () => {
      const previousMonthDate = dayjs().subtract(1, 'month');
      return {month: previousMonthDate.month() + 1, year: previousMonthDate.year()}
    },
  }
  return methods.hasOwnProperty(value) ? methods[value]() : undefined;
}

const getMonthRangeLabel = (startDateObj, endDateObj) => {
  const month = startDateObj.format('MMMM');
  const endDayNum = parseInt(endDateObj.format('D'));
  let label = `${month} 1`;
  if (endDayNum !== 1) label = `${label}-${endDayNum}`;
  return label;
}

const dayFilter = (i, day) => i.day === day;

const defaultSpaceProps = {
  size: 'large', style: {width: '100%'}
}

const OverviewPage = ({
  getTags,
  getOverview,
  getOverviewSubscriptionsTotals,
  getYearlyOverview,
  isKnownEmployee,
  isAdmin,
  bankingCompanyState,
  companyCountry,
  companyId,
  requiredCardVerification,
  tags
}) => {
  const [t] = useTranslation(['main', 'subscriptions']);
  const [showLoader, setShowLoader] = useState(true);
  const [spendingData, setSpendingData] = useState({
    query: { month, year },
    categories: [],
    chartData: [],
    chartDataServices: [],
    costCenter: [],
    upcoming: [],
    variations: [],
    yearly: [],
    yearlyByServices: []
  })
  const [totals, setTotals] = useState({
    actual: counterDefaultProps,
    expected: counterDefaultProps,
  });
  const [subscriptionsTotals, setSubscriptionsTotals] = useState({
    loading: true,
    discovered: 0,
    free: 0,
    paid: 0,
    total: 0
  });
  const [requiredCompleteKyc, setRequiredCompleteKyc] = useState(false);
  const [filterProps, setFilterProps] = useState({
    range: CURRENT_MONTH,
    tags: [],
  });
  const [chartView, setChartView] = useState('all');

  const isEmployeeChanged = useIsEmployeeChanged();

  const isEmptySpending = useMemo(() => {
    return requiredCardVerification || requiredCompleteKyc || subscriptionsTotals.total === 0;
  }, [requiredCardVerification, requiredCompleteKyc, subscriptionsTotals]);

  const actualTimeRange = useMemo(() => {
    const range = filterProps.range;
    const timeRangeActions = {
      [CURRENT_MONTH]: () => {
        const date = moment();
        return getMonthRangeLabel(date, date);
      },
      [PREVIOUS_MONTH]: () => {
        const startDate = moment().subtract(1, 'month');
        const endDate = startDate.endOf('month');
        return getMonthRangeLabel(startDate, endDate);
      },
      [YEARLY]: () => {
        const monthYearFormat = 'MMM YY';
        const currentMonth = moment().format(monthYearFormat);
        const startMonth = moment().subtract(11, 'months').format(monthYearFormat);
        return `${startMonth}-${currentMonth}`;
      }
    }
    return timeRangeActions.hasOwnProperty(range) ? timeRangeActions[range]() : '';
  }, [filterProps]);

  const finishLoading = () => setShowLoader(false);

  const getPlaceholderLabel = (key) => t(`subscriptions:overview.chart.placeholder.${key}`);

  const calculateSubscriptionsChart = ({range, spending, upcoming, variant}) => {
    const {day: currentDayNum} = helpers.getCurrentDate();
    const daisInMonth = dayjs().daysInMonth();
    let expected = 0, current = 0;
    if (variant === 'yearly') {
      const today = moment();
      let monthIndex = today.month();
      let currentMonthInformation, currentMonthSpending, label;
      return helpers.range(1, 12).map(i => {
        monthIndex = monthIndex === 12 ? 1 : monthIndex + 1;
        today.set('month', monthIndex);
        label = today.format('MMM');
        currentMonthInformation = {
          label,
          name: label
        };
        currentMonthSpending = spending.find(d => d.month === monthIndex);
        current += currentMonthSpending?.amount || 0;
        expected += currentMonthSpending?.expected_amount || 0;
        return {
          ...currentMonthInformation,
          current,
          expected,
          isActive: i === 12
        };
      });
    } else {
      const isCurrentMonthSpending = range === CURRENT_MONTH;
      const monthDate = isCurrentMonthSpending ? moment() : moment().subtract(1, 'month');
      return helpers.range(1, daisInMonth).map(day => {
        let date = monthDate.set('date', day);
        let currentDayInformation = {
          date: date.format('YYYY-MM-DD'),
          label: date.format('Do MMMM'),
          name: day
        };
        let currentDaySpending = spending.find(i => dayFilter(i, day));
        let upcomingDaySpending = upcoming.filter(i => dayFilter(i, day));
        if (currentDaySpending) {
          current += subscriptionsHelpers.getSpendingServicesAmount(currentDaySpending);
          expected += subscriptionsHelpers.getSpendingServicesAmount(currentDaySpending, 'expected_amount');
        }

        // add upcoming spending to expected
        expected += subscriptionsHelpers.getSpendingServicesAmount({services: upcomingDaySpending}, 'amount');

        currentDayInformation = {...currentDayInformation, expected};
        if (isCurrentMonthSpending) {
          if (day <= currentDayNum) currentDayInformation = {...currentDayInformation, current};
          if (day === currentDayNum) currentDayInformation = {...currentDayInformation, isActive: true};
        } else {
          currentDayInformation = {...currentDayInformation, current};
        }
        return currentDayInformation;
      });
    }
  }

  const calculateTotals = ({spending, variant} = {}) => {
    let actual = 0, expected = 0;
    spending.forEach(spendingItem => {
      if (variant === YEARLY) spendingItem = {services: [spendingItem]};
      actual += subscriptionsHelpers.getSpendingServicesAmount(spendingItem, 'amount');
      expected += subscriptionsHelpers.getSpendingServicesAmount(spendingItem, 'expected_amount');
    });
    const getTotal = (value) => ({isLoaded: true, value});

    setTotals({
      actual: getTotal(actual),
      expected: getTotal(expected)
    });
  }

  const formatCategories = (data) => {
    let categories = data.map(i => ({name: i.category?.name, value: i.amount || 0})).sort((a, b) => b.value - a.value);
    const visibleCount = 5;
    if (categories.length > visibleCount) {
      const otherCategories = [...categories].splice(visibleCount);
      categories = [
        ...categories.slice(0, visibleCount),
        {
          name: t('other'),
          value: otherCategories.reduce((n, {value}) => n + value, 0),
        }
      ];
    }
    // add colors to pie chart item
    categories = categories.map((c, index) => ({...c, color: helpers.getColorByIndex(index)}))
    return categories;
  }

  const formatCostCenter = (data) => {
    data = data.map(i => ({actual: i.amount || 0, expected: i.budget || 0, name: i.cost_center}));
    let overBudgetSpending = data.filter(d => d.actual - d.expected > 0).sort((a, b) => b.actual - a.actual);
    let inBudgetSpending = data.filter(d => d.expected - d.actual >= 0).sort((a, b) => b.actual - a.actual);
    return [...overBudgetSpending, ...inBudgetSpending];
  }

  const getFormattedSpending = (item) => ({
    ...item,
    actual: item.amount || 0,
    budget: item.budget || 0,
    service: {
      logo: item.service_logo,
      name: item.service
    }
  })

  const formatUpcoming = (data, currentMonth) => {
    if (currentMonth === undefined) currentMonth = dayjs();
    return data
      .map(i => ({...getFormattedSpending(i), day: i.day, date: currentMonth.date(i.day).format('DD/MM/YY')}))
      .sort((a, b) => a.day - b.day);
  }

  const formatVariations = (data) => data
    .map(i => ({...getFormattedSpending(i), expected: i.expected_amount || 0, subscription_id: i.subscription_id}))
    .filter(i => i.expected !== i.actual)
    .sort((a, b) => (a.expected - a.actual) - (b.expected - b.actual));

  const formatChartDataServices = (currentMonthSpending, upcomingSpending = []) => {
    const daisInMonth = dayjs().daysInMonth();
    return helpers.range(1, daisInMonth).map(day => {
      let actual = [], expected = [];
      let actualSpend = 0, expectedSpend = 0;
      let currentDaySpending = currentMonthSpending.find(i => dayFilter(i, day));
      let upcomingDaySpending = upcomingSpending.filter(i => dayFilter(i, day));
      if (currentDaySpending) {
        actual = subscriptionsHelpers.getSpendingServicesList(currentDaySpending);
        actualSpend = subscriptionsHelpers.getSpendingServicesAmount(currentDaySpending);

        expected = subscriptionsHelpers.getSpendingServicesList(currentDaySpending, 'expected_amount');
        expectedSpend = subscriptionsHelpers.getSpendingServicesAmount(currentDaySpending, 'expected_amount');
      }
      // add upcoming spending to expected list
      if (Boolean(upcomingDaySpending.length)) {
        expected = [
          ...expected,
          ...subscriptionsHelpers.getSpendingServicesList({services: upcomingDaySpending})
        ];
      }
      return {
        actual,
        actualSpend,
        day,
        expected,
        expectedSpend
      };
    });
  }

  const formatYearlySpending = (data) => {
    let startDate = dayjs().subtract(11, 'month');
    const spending = [];
    helpers.range(1, 12).forEach(i => {
      let month = startDate.month() + 1;
      let monthSpending = data.find(d => d.month === month);
      spending.push({
        label: startDate.format('MMM'),
        actual: monthSpending?.amount || 0,
        expected: monthSpending?.expected_amount || 0
      });
      startDate = startDate.add(1, 'month');
    });
    return spending;
  }

  const formatYearlyByServicesSpending = (data) => data
    .map(i => ({...getFormattedSpending(i), expected: i?.expected_amount || 0}))
    .sort((a, b) => b.actual - a.actual)
    .slice(0, 15);

  const getOverviewData = ({
    range = CURRENT_MONTH,
    query = undefined,
    variant = 'monthly'
  } = {}) => {
    let requestQuery = query || spendingData.query;
    const endpointFunc = variant === 'monthly' ? getOverview : getYearlyOverview;
    setShowLoader(true);
    endpointFunc(
      requestQuery,
      (data) => {
        const gDataP = (name) => data?.[name] || [];
        const categories = gDataP('categories').filter(c => c.category);
        const costCenter = gDataP('cost_center');
        const spending = gDataP('spending');
        const upcoming = gDataP('upcoming');
        const variations = gDataP('variations');
        const yearly = gDataP('spending');
        const yearlyByServices = gDataP('spending_by_service');
        const isCurrentMonthRange = range === CURRENT_MONTH;

        const latestSpendingData = {
          ...spendingData,
          categories: formatCategories(categories),
          chartData: calculateSubscriptionsChart({range, spending, upcoming, variant}),
          chartDataServices: formatChartDataServices(spending, isCurrentMonthRange ? upcoming : []),
          costCenter: formatCostCenter(costCenter),
          variations: formatVariations(variations),
          upcoming: isCurrentMonthRange ? formatUpcoming(upcoming) : spendingData.upcoming,
          yearly: formatYearlySpending(yearly),
          yearlyByServices: formatYearlyByServicesSpending(yearlyByServices)
        }

        setSpendingData(latestSpendingData);
        calculateTotals({spending, variant});
        finishLoading();

        if (range === CURRENT_MONTH) getNextMonthOverviewData(latestSpendingData);
      },
      finishLoading
    );
  }

  const getNextMonthOverviewData = (spendingData) => {
    const nextDayRange = 10;
    const startDate = dayjs();
    const endDate = dayjs().add(nextDayRange, 'day');
    // load data for next month if end date is next month
    if (endDate.month() !== startDate.month()) {
      getOverview(
        {month: endDate.month() + 1, year: endDate.year()},
        (resp) => {
          const endDateDayNumber = endDate.date();
          const upcoming = (resp?.upcoming || []).filter(i => i.day <= endDateDayNumber);
          setSpendingData({
            ...spendingData,
            upcoming: [
              ...spendingData.upcoming,
              ...formatUpcoming(upcoming, endDate),
            ]
          });
        }
      )
    }
  }

  const getOverviewSubscriptionsTotalsData = () => {
    !subscriptionsTotals.loading && setSubscriptionsTotals({...subscriptionsTotals, loading: true});
    getOverviewSubscriptionsTotals(
      null,
      (data) => {
        const gDataP = (name) => data?.[name] || 0;
        setSubscriptionsTotals({
          ...subscriptionsTotals,
          loading: false,
          discovered: gDataP('total_discovered'),
          free: gDataP('total_authorized_free'),
          paid: gDataP('total_authorized_paid'),
          total: gDataP('total')
        });
      },
      () => {
        setSubscriptionsTotals({
          ...subscriptionsTotals,
          loading: false
        });
      }
    )
  }

  const loadOverviewInformation = () => {
    getOverviewData();
    getOverviewSubscriptionsTotalsData();
  }

  useEffect(() => {
    if (isKnownEmployee) {
      loadOverviewInformation();
    }
  }, [isKnownEmployee]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (companyId) {
      getTags();
    }
  }, [companyId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isEmployeeChanged) {
      setShowLoader(true);
      setChartView('all');
      setFilterProps({...filterProps, range: CURRENT_MONTH});
      loadOverviewInformation();
    }
  }, [isEmployeeChanged]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let isRequired = false;
    const isSupportedKycCountry = kycHelpers.isSupportedCountry(companyCountry);
    if (bankingCompanyState && isSupportedKycCountry) {
      isRequired = bankingCompanyState.stage !== 'card_stage';
      if (isRequired !== requiredCompleteKyc) setRequiredCompleteKyc(isRequired);
    }
    if (isRequired !== requiredCompleteKyc) setRequiredCompleteKyc(isRequired);
  }, [bankingCompanyState, companyCountry]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterSpending = (params) => {
    let variant = 'monthly';
    const range = params?.range;
    let query = {
      cost_centers: params?.tags || []
    }
    if (params.range === YEARLY) {
      variant = 'yearly';
      const {date: todayDate} = helpers.getCurrentDate();
      const dateFormat = 'YYYY-MM';
      const endDate = todayDate.format(dateFormat);
      const startDate = todayDate.subtract(11, 'month').format(dateFormat);
      query = {
        ...query,
        start_date: startDate,
        end_date: endDate
      };
    } else {
      const dateParams = getRangeDate(range);
      if (dateParams) query = { ...query, ...dateParams };
    }
    getOverviewData({range, query, variant});
  }

  const datePickerItems = useMemo(() => {
    const getDatePickerLabel = (key) => t(`subscriptions:overview.datePickerOptions.${key}`);
    return [
      {
        label: getDatePickerLabel('thisMonth'),
        key: CURRENT_MONTH,
      },
      {
        label: getDatePickerLabel('pastMonth'),
        key: PREVIOUS_MONTH,
      },
      {
        label: getDatePickerLabel('past12Months'),
        key: YEARLY,
      },
    ]
  }, [t]);

  const handleFilter = (key, value) => {
    let props = {...filterProps, [key]: value};
    setFilterProps(props);
    handleFilterSpending(props);
    helpers.logEvent(firebaseEvents.OVERVIEW_USE_FILTER, {name: key});
  }

  const tagOptions = useMemo(() => helpers.getTagOptions(tags), [tags]);

  const spendingChartVariant = useMemo(() => {
    return chartView !== 'all' || filterProps.range === YEARLY ? 'barchart' : 'linechart';
  }, [chartView, filterProps]);

  const spendingProps = useMemo(() => {
    let placeholder = false;
    let enabled = false;
    if (!subscriptionsTotals.loading) {
      enabled = Boolean(subscriptionsTotals.total);
      placeholder = !enabled;
    }
    return {
      placeholder,
      enabled
    }
  }, [subscriptionsTotals]);

  const spendingExtra = spendingProps.enabled && (
    <Space size='small' align='center' className='spending-selectors'>
      <Select
        maxTagCount={1}
        mode='multiple'
        onChange={(value) => handleFilter('tags', value)}
        options={tagOptions}
        placeholder={t('costCenter')}
        size='large'
      />
      <OverviewDropdown
        defaultSelected={filterProps.range}
        menuItems={datePickerItems}
        onSelect={(value) => handleFilter('range', value)}
        trigger={['click']}
      />
    </Space>
  );

  return (
    <StyledOverviewPageContainer>
      <PageDocumentDetails title={t('pageTitles.overview')} />
      <BalancePageHeader
        breadcrumbs={[{title: t('overview')}]}
      />
      <StyledOverviewPageContent
        align='start'
        {...defaultSpaceProps}
      >
        <StyledOverviewPageSpin
          wrapperClassName='overview-main-spin'
          size='large'
          spinning={showLoader}
        >
          <Space
            align='start'
            {...defaultSpaceProps}
          >
            <Space
              direction='vertical'
              {...defaultSpaceProps}
            >
              {isAdmin && (
                <UnmountClosed isOpened={requiredCompleteKyc}>
                  <CompleteKycCard
                    variant='kyc'
                  />
                </UnmountClosed>
              )}
              <UnmountClosed isOpened={requiredCardVerification}>
                <CompleteKycCard variant='banking' />
              </UnmountClosed>
              <StyledOverviewPageCard
                bordered={false}
                extra={spendingExtra}
                title={isEmptySpending ? undefined : t('spending')}
              >
                {spendingProps.placeholder ? (
                  <StyledOverviewPageSpendingPlaceholder
                    fullheight={isEmptySpending && !(requiredCompleteKyc || requiredCardVerification)}
                  >
                    <Space
                      align='center'
                      direction='vertical'
                      size='middle'
                    >
                      <h3>{getPlaceholderLabel('title')}</h3>
                      <p>{getPlaceholderLabel('description')}</p>
                    </Space>
                    <GraphPlaceholder/>
                  </StyledOverviewPageSpendingPlaceholder>
                ) : spendingProps.enabled && (
                  <Spending
                    barChartData={chartView === 'all' ? spendingData.yearly : spendingData.yearlyByServices}
                    chartVariant={spendingChartVariant}
                    segmentProps={{
                      onChange: setChartView,
                      value: chartView
                    }}
                    lineChartData={spendingData.chartData}
                    lineChartDataServices={spendingData.chartDataServices}
                    counterProps={{
                      actualTimeRange,
                      data: totals,
                      enableBudgetCounter: filterProps.range !== YEARLY
                    }}
                  />
                )}
              </StyledOverviewPageCard>
              {spendingProps.enabled && (
                <SpendingDetails
                  categories={spendingData.categories}
                  costCenter={spendingData.costCenter}
                  loading={showLoader}
                />
              )}
            </Space>
          </Space>
        </StyledOverviewPageSpin>
        <StyledOverviewPageCardRightSideSpace
          direction='vertical'
          size='middle'
        >
          <SubscriptionsDetails
            loading={showLoader}
            subscriptionsTotals={subscriptionsTotals}
            variations={spendingData.variations}
            upcoming={spendingData.upcoming}
          />
        </StyledOverviewPageCardRightSideSpace>
      </StyledOverviewPageContent>
    </StyledOverviewPageContainer>
  );
}

const mapStateToProps = state => {
  const {isKnownEmployee, isAdmin} = state.user;
  const {companyState: bankingCompanyState, verification} = state.banking;
  const {country: companyCountry, id: companyId, tags} = state.company;
  const requiredCardVerification = helpers.getObjProp(verification, 'isRequired') || false;

  return {
    bankingCompanyState,
    companyCountry,
    companyId,
    isKnownEmployee,
    isAdmin,
    requiredCardVerification,
    tags
  }
}

const mapDispatchToProps = {
  getTags: companyActions.getTags,
  getOverview: overviewActions.getOverview,
  getOverviewSubscriptionsTotals: overviewActions.getOverviewSubscriptionsTotals,
  getYearlyOverview: overviewActions.getYearlyOverview
}

export default connect(mapStateToProps, mapDispatchToProps)(OverviewPage);
