import React from 'react';
import {Button, Space} from 'antd';
import {helpers} from '../../../helpers';
import UserRoleSelect from './tabComponents/UserRoleSelect';
import UserDetails from '../../UserDetails';
import {userStatusesConstants, userRolesConstants} from '../../../constants';

const {ACTIVE, DISABLED, EXTERNAL, PENDING} = userStatusesConstants;
const {ADMIN, SUPPORT, USER} = userRolesConstants;

const gObjProp = helpers.getObjProp;

const userRoles = {
  [ADMIN]: {
    key: 'userRoles.admin',
    color: '#52C41A'
  },
  [USER]: {
    key: 'userRoles.user',
    color: '#D9D9D9'
  },
  [SUPPORT]: {
    key: 'userRoles.support',
    color: '#D9D9D9'
  },
};


export const teamsHelpers = {
  getEmployeeDetails: (employee) => {
    if (employee && typeof employee === 'object') {
      return <UserDetails user={employee} />;
    } else {
      return null;
    }
  },
  getRole: ({employee, onChange}) => {
    if (employee && typeof employee === 'object') {
      return (
        <UserRoleSelect
          defaultValue={employee.role}
          email={employee.email}
          onClick={(e) => e.stopPropagation()}
          onChange={(value) => onChange && onChange({value, employee})}
        />
      )
    } else {
      return null;
    }
  },
  getActions: ({employee, editorEmail, t, onClick, isEditorSupport}) => {
    if (employee && typeof employee === 'object') {
      const status = gObjProp(employee, 'account_status');
      const email = gObjProp(employee, 'email');
      const role = gObjProp(employee, 'role');
      const isSupportRole = role === SUPPORT;
      if (email === editorEmail) return ;

      const buttons = {
        [ACTIVE]: (
          <Button
            danger
            onClick={(e) => onClick({e, action: 'disable', employee})}
            type='text'
          >
            {t('main:disable')}
          </Button>
        ),
        [DISABLED]: (
          <Button
            danger
            onClick={(e) => onClick({e, action: 'enable', employee})}
            type='text'
          >
            {t('main:enable')}
          </Button>
        ),
        [EXTERNAL]: (
          <Button
            className='primary'
            onClick={(e) => onClick({e, action: 'invite', employee})}
            type='text'
          >
            {t('main:invite')}
          </Button>
        ),
        [PENDING]: (
          <>
            <Button
              className='primary'
              onClick={(e) => onClick({e, action: 'resend-invite', employee})}
              type='text'
            >
              {t('teams:resendInvite')}
            </Button>

            <Button
              danger
              onClick={(e) => onClick({e, action: 'delete', employee})}
              type='text'
            >
              {t('main:cancel')}
            </Button>
          </>
        ),
      };
      // hide actions is editor has support role && edited user has support role as well
      if (isSupportRole && !isEditorSupport && [ACTIVE, DISABLED].includes(status)) return ;
      return buttons.hasOwnProperty(status) ? (
        <Space className='actions'>
          {buttons[status]}
        </Space>
      ) : null;
    } else {
      return null;
    }
  },
  getUserRole: (role) => helpers.getStatus(role, userRoles),
  getConfirmModalProps: ({t, action, employee, ...rest}) => ({
    cancelButtonProps: {size: 'large', className: 'green-btn-outlined'},
    cancelText: t('no'),
    icon: null,
    okButtonProps: {size: 'large', type: 'primary'},
    okText: t('yes'),
    title: t('teams:modal.actionModal.title', {action, name: employee?.full_name || employee?.email}),
    ...rest
  }),
  getReInvitedUserData: (employee) => {
    let invitations = employee.invitation_attempts;
    invitations = invitations ? JSON.parse(invitations.replace(/'/g, '"')) : [];
    invitations.push(`${Math.floor(Date.now() / 1000)}`)
    return {
      ...employee,
      account_status: userStatusesConstants.PENDING,
      invitation_attempts: JSON.stringify(invitations)
    }
  }
}
