import styled from 'styled-components';
import {typography} from '../../../../utils/css';

const StyledTransactionDetailsHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTransactionDetailsHeaderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;
  
  a {
    align-items: center;
    border-radius: ${props => props.theme.border_radius.standard};
    color: ${props => props.theme.colors.neutral_10};
    display: flex;
    height: 36px;
    padding: 8px;
    transition: .2s;
    
    svg {
      path {
        fill: ${props => props.theme.colors.neutral_10};
      }
    }
    
    &:hover {
      background-color: ${props => props.theme.colors.neutral_2};
    }
  }

  .date,
  .category {
    ${typography.body.regular};
  }
  
  .date,
  .category {
    color: ${props => props.theme.colors.neutral_7};
  }
  
  .date,
  .category {
    color: ${props => props.theme.colors.neutral_10};
  }

  .price {
    ${typography.heading.h4};
    
    &.returned {
      color: ${props => props.theme.colors.neutral_7};
      text-decoration: line-through;
    }
  }
`;

export {
  StyledTransactionDetailsHeader,
  StyledTransactionDetailsHeaderItem
};
