import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Avatar, Input, Form, Button} from 'antd';
import {
  StyledCompanyInviteModal,
  StyledCompanyInviteModalButton,
  StyledCompanyInviteModalSpace,
  StyledCompanyInviteModalHeader
} from './StyledCompanyInviteModal';
import AntdModal from '../../AntdModal';
import SpinSmall from '../../SpinSmall';
import logo from '../../../static/images/withless-icon-logo-white.svg';
import {ReactComponent as ErrorSvgImage} from '../../../static/images/error.svg';
import {EmailRegexp, helpers, NameRegexp} from '../../../helpers';
import {authActions, userActions} from '../../../state/actions';

const {Item} = Form;

const gObjProp = helpers.getObjProp;

const initialFormValues = {
  email: '',
  user_first_name: '',
  user_last_name: '',
}

const CompanyInviteModal = ({
  open,
  handleClose,
  user,
  companyInviteFinish,
  getCompanies,
  companyInviteToken,
  companies,
  invitationDetails
}) => {
  const [trans] = useTranslation(['quickStart', 'main', 'auth']);
  const [form] = Form.useForm();
  const [showFormModal, setShowFormModal] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [invalidInvitation, setInvalidInvitation] = useState({expired: false, alreadyOnboarded: true});
  const [isFinished, setIsFinished] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);

  useEffect(() => {
    if (open) {
      setShowWelcomeModal(true);
    } else {
      if (showFormModal) {
        setShowFormModal(false);
        formLoading && setFormLoading(false);
      }
      showWelcomeModal && setShowWelcomeModal(false);
    }
    showFormModal && setShowFormModal(false);
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (open && contentLoading) {
      if (user && user.has_any_company) {
        if (companies.length > 0) setContentLoading(false);
      } else {
        setContentLoading(false);
      }
    }
  }, [companies, open, user]); // eslint-disable-line react-hooks/exhaustive-deps

  const t = (key, options) => trans(`invite.${key}`, options);

  const tMain = (key, options) => trans(`main:${key}`, options);

  const mainValidationT = (key, options) => tMain(`validation.${key}`, options);

  const onClose = () => handleClose && handleClose();

  useEffect(() => {
    if (isFinished) return;
    if (invitationDetails && !helpers.isEmptyObject(invitationDetails)) {
      const alreadyOnboarded = companies.find(c => c.company_id === invitationDetails.company_id);
      setInvalidInvitation({
        ...invalidInvitation,
        alreadyOnboarded
      });
    } else {
      setInvalidInvitation({
        ...invalidInvitation,
        expired: true
      });
    }
  }, [companies, invitationDetails, isFinished]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user) {
      const email = gObjProp(user, 'email');
      let fullName = gObjProp(user, 'full_name');
      let firstName = '';
      let lastName = '';

      if (fullName) {
        fullName = fullName.split(' ');
        firstName = fullName[0];
        if (fullName.length > 1) lastName = fullName[1];
      }

      form.setFieldsValue({
        user_first_name: firstName,
        user_last_name: lastName,
        email
      });
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleGetStarted = () => {
    if (isFinished || invalidInvitation.alreadyOnboarded || invalidInvitation.expired) {
      onClose();
    } else {
      setShowWelcomeModal(false);
      setShowFormModal(true);
    }
  }

  const getRequiredRules = (key) => {
    return [
      {required: true, message: mainValidationT('pleaseEnterYour', {name: key.toLowerCase()})}
    ]
  }

  const getNameRules = (key) => {
    return [
      ...getRequiredRules(key),
      {pattern: NameRegexp, message: mainValidationT('enterValidName')}
    ]
  };

  const handleOnFormKeyUp = (e) => e.key.toLowerCase() === 'enter' && handleOnFormFinish();

  const handleOnFormFinish = (fields) => {
    const data = {
      ...helpers.trimAllValues(fields),
      invitation_token: companyInviteToken
    };
    setFormLoading(true);
    companyInviteFinish(
      data,
      (response) => {
        getCompanies();
        setFormLoading(false);
        setIsFinished(true);
        setShowFormModal(false);
        setShowWelcomeModal(true);
      },
      (err) => {
        if (err.hasOwnProperty('errors')) {
          const fields = helpers.getFormServerErrorFields(err);
          form.setFields(fields);
        } else if (err.hasOwnProperty('message')) {
          form.setFields([{name: 'email', errors: [err.message]}]);
        }
        setFormLoading(false);
      }
    );
  }

  const getInvDetails = (key) => invitationDetails ? gObjProp(invitationDetails, key, '') : '';

  const companyName = getInvDetails('company_name');
  const userName = gObjProp(user, 'full_name');

  const firstNameLabel = `${tMain('first')} ${tMain('name')}`;
  const lastNameLabel = `${tMain('last')} ${tMain('name')}`;

  const isExpiredInvitation = invalidInvitation.expired;

  return (
    <>
      {/* Welcome modal */}
      <StyledCompanyInviteModal
        centered={true}
        closeIcon={isExpiredInvitation}
        onCancel={isExpiredInvitation ? onClose : undefined}
        header={isExpiredInvitation}
        footer={null}
        open={showWelcomeModal}
        width={408}
      >
        <SpinSmall spinning={contentLoading}>
          {isExpiredInvitation ? (
            <StyledCompanyInviteModalSpace
              direction='vertical'
              size='large'
            >
              <ErrorSvgImage />
              <StyledCompanyInviteModalSpace
                direction='vertical'
                size='middle'
              >
                <h1>{t('invitationExpired')}</h1>
                <p>{t('invitationExpiredDescription')}</p>
              </StyledCompanyInviteModalSpace>
            </StyledCompanyInviteModalSpace>
          ) : (
            <StyledCompanyInviteModalSpace
              direction='vertical'
              size='large'
            >
              <StyledCompanyInviteModalHeader>
                <Avatar src={getInvDetails('company_logo')} size={180}>
                  {helpers.getInitials(companyName)}
                </Avatar>
                <Avatar src={logo} size={64} />
              </StyledCompanyInviteModalHeader>
              <StyledCompanyInviteModalSpace
                direction='vertical'
                size='middle'
              >
                {invalidInvitation.alreadyOnboarded ? (
                  <>
                    <h1>{t('alreadyOnboarded')}</h1>
                    <p>
                      {t('welcomeBack1', {user_name: userName})}
                      <br/>
                      {t('welcomeBack2')} <b>{companyName}</b>.
                    </p>
                  </>
                ) : (
                  <>
                    <h1>{t('welcomeToWithLess')}</h1>
                    <p>
                      {t('congratulations1', {user_name: userName})}
                      <br/>
                      {t('congratulations2')} <b>{companyName}</b>.
                    </p>
                  </>
                )}
              </StyledCompanyInviteModalSpace>
              <StyledCompanyInviteModalButton
                onClick={handleGetStarted}
                type='primary'
                size='large'
              >
                {isFinished || invalidInvitation.alreadyOnboarded ? tMain('close') : t('getStarted')}
              </StyledCompanyInviteModalButton>
            </StyledCompanyInviteModalSpace>
          )}
        </SpinSmall>
      </StyledCompanyInviteModal>

      {/* Form modal */}
      <AntdModal
        cancelButton={false}
        onCancel={onClose}
        footer={[
          <Button
            key='submit'
            loading={formLoading}
            onClick={() => form.submit()}
            size='large'
            type='primary'
          >
            {tMain('confirm')}
          </Button>
        ]}
        forceRender
        open={showFormModal}
        title={t('enterYourDetails')}
      >
        <SpinSmall spinning={formLoading}>
          <Form
            initialValues={initialFormValues}
            layout='vertical'
            form={form}
            onKeyUp={handleOnFormKeyUp}
            onFinish={handleOnFormFinish}
            requiredMark={false}
          >
            <Item
              label={firstNameLabel}
              name='user_first_name'
              rules={getNameRules(firstNameLabel)}
              required
            >
              <Input
                maxLength={50}
                placeholder={firstNameLabel}
                size='large'
              />
            </Item>
            <Item
              label={lastNameLabel}
              name='user_last_name'
              rules={getNameRules(lastNameLabel)}
              required
            >
              <Input
                maxLength={50}
                placeholder={lastNameLabel}
                size='large'
              />
            </Item>
            <Item
              label={tMain('email')}
              name='email'
              rules={[
                ...getRequiredRules(tMain('email')),
                {pattern: EmailRegexp, message: mainValidationT('enterValidEmail')}
              ]}
              required
            >
              <Input
                type='email'
                placeholder={tMain('email')}
                size='large'
              />
            </Item>
          </Form>
        </SpinSmall>
      </AntdModal>
    </>

  );
}

CompanyInviteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  const {user, companies} = state.user;
  const {companyInviteToken, invitationDetails} = state.auth;
  return {
    companies,
    companyInviteToken,
    invitationDetails,
    user
  }
}

const mapDispatchToProps = {
  companyInviteFinish: authActions.companyInviteFinish,
  getCompanies: userActions.getCompanies
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInviteModal);
