import styled from 'styled-components';
import {borders, typography} from '../../../../utils/css';

const PANEL_BODY_PADDING = '24px';

const StyledTransactionDetailsPanel = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

const StyledTransactionDetailsPanelContainer = styled.div` 
  position: ${props => props.fixed ? 'fixed' : 'relative'};
  top: ${props => props.fixed ? '90px' : '0px'};
  width: inherit;
  
  & > div {
    opacity: ${props => props.hiddenOverflow ? 0 : 1};
    transition: opacity .3s ease;
  }
`;

const StyledTransactionDetailsContent = styled.div`
  ${borders.light};
  background-color: ${props => props.theme.colors.neutral_1};
  margin-left: 24px;
`;

const StyledPanelBody = styled.div`
  padding: ${PANEL_BODY_PADDING};
  overflow: auto;
  max-height: calc(100vh - (66px + ${PANEL_BODY_PADDING} * 2 + 56px));
`;

const StyledPanelHeader = styled.div`
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.neutral_4};
  display: flex;
  padding: 0 24px;
  min-height: 56px;

  button.ant-btn {
    ${typography.heading.h5};
    align-items: center;
    color: ${props => props.theme.colors.neutral_10};
    display: flex;
    border: none;
    padding: 0 7px 0 0;

    svg {
      path {
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        fill: ${props => props.theme.colors.neutral_6};
        stroke: ${props => props.theme.colors.neutral_6};
      }
    }

    &:hover {
      svg {
        path {
          fill: rgba(0, 0, 0, 0.88);
          stroke: rgba(0, 0, 0, 0.88);
        }
      }
    }
  }
`;

export {
  StyledTransactionDetailsPanel,
  StyledTransactionDetailsPanelContainer,
  StyledTransactionDetailsContent,
  StyledPanelHeader,
  StyledPanelBody
};
