import React from 'react';
import {Modal} from 'antd';
import {ExclamationCircleFilled} from '@ant-design/icons';
import CreditCardDetails from '../CardsPage/CreditCardDetails';
import UserDetails from '../../UserDetails';
import {helpers} from '../../../helpers';
import {
  expenseInvoicesStatusConstants,
  cardPaymentStatusesConstants,
  transactionTypesConstants
} from '../../../constants';
import {RepeatIcon, TransactionIcon, TransactionAddIcon} from '../../../icons';

const {CARD_TRANSACTION} = transactionTypesConstants;

const {AUTHORIZATION_ACCEPTED, CLEARED, DECLINED, REFUND, REVERSED, SETTLED} = cardPaymentStatusesConstants;

const gMV = helpers.getMoneyView;

const transactionDetailsMaxWidth = `${472 + 24}px`;
const tableCollapseWidth = `calc(100% - ${transactionDetailsMaxWidth})`;

const cardPaymentStatuses = {
  [AUTHORIZATION_ACCEPTED]: {
    key: 'cardPaymentStatuses.accepted',
    color: '#52C41A'
  },
  [CLEARED]: {
    key: 'cardPaymentStatuses.cleared',
    color: '#1890FF'
  },
  [DECLINED]: {
    key: 'cardPaymentStatuses.declined',
    color: '#FF7875'
  },
  [REFUND]: {
    key: 'cardPaymentStatuses.refund',
    color: '#722ED1'
  },
  [REVERSED]: {
    key: 'cardPaymentStatuses.reversed',
    color: '#FFCC00'
  },
  [SETTLED]: {
    key: 'cardPaymentStatuses.settled',
    color: '#52C41A'
  },
};

export const transactionsHelpers = {
  getAnimationStyles: ({
    defaultTableWidth = '100%',
    duration
  } = {}) => {
    const defaultStyle = {
      transition: `width ${duration}ms ease-in-out`
    };
    return {
      details: {
        default: defaultStyle,
        entering: {width: transactionDetailsMaxWidth},
        entered: {width: transactionDetailsMaxWidth},
        exiting: {width: 0},
        exited: {width: 0}
      },
      table: {
        default: defaultStyle,
        entering: {width: tableCollapseWidth},
        entered: {width: tableCollapseWidth},
        exiting: {width: defaultTableWidth},
        exited: {width: defaultTableWidth}
      }
    }
  },
  isReturnedTransaction: (transaction) => transaction && [DECLINED, REVERSED].includes(transaction.payment_status),
  getTransactionAmount: ({transaction, variant, groupingKey = 'isMonthGrouping'}) => {
    if (transaction && typeof transaction === 'object') {
      let className = '';
      let {amount} = transaction;
      if (transaction[groupingKey]) {
        className = 'total-amount';
        amount = transaction[variant] || 0;
        if (amount > 0 && variant === 'outcome') className = `${className} danger-text`;
        if (variant === 'outcome' && Math.sign(amount) > 0) amount = amount * -1;
        return <span className={className}>{gMV(amount)}</span>;
      } else {
        const isIncoming = transaction.is_incoming || false;
        if ((isIncoming && variant !== 'income') || (!isIncoming && variant !== 'outcome')) return '';
        const price = gMV(amount);
        const isReturnedTransaction = transactionsHelpers.isReturnedTransaction(transaction);
        if (!isIncoming && !isReturnedTransaction) className = `${className} danger-text`;
        if (isReturnedTransaction) className = `${className} returned-price`;
        return amount ? isIncoming ? price : <span className={className}>-{gMV(Math.abs(amount))}</span> : null;
      }
    } else {
      return null
    }
  },
  getTransactionAuthor: (transaction) => {
    if (transaction && typeof transaction === 'object' && transaction.transaction_type === CARD_TRANSACTION) {
      return (
        <UserDetails
          email={false}
          user={transaction.user}
        />
      );
    } else {
      return null;
    }
  },
  getTransactionSource: (transaction) => {
    let value = '';
    if (typeof transaction === 'object') {
      const description = helpers.getObjProp(transaction, 'description');
      const source = helpers.getObjProp(transaction, 'source');
      if (source) {
        value = source;
      } else if (description && description !== '') {
        value = helpers.cutString(description.split(' ')[0], 20)
      }
    }
    return value;
  },
  getTransactionStatus: (status) => {
    const statuses = {...cardPaymentStatuses};
    Object.keys(statuses).forEach(key => {
      statuses[key].bgColor = helpers.hex2rgba(statuses[key].color, 0.15);
    })
    return helpers.getStatus(status, statuses);
  },
  getTransactionType: (transaction) => {
    if (transaction && typeof transaction === 'object') {
      return (
        <span className='transaction-type'>
          {transaction.transaction_type === CARD_TRANSACTION ? (
            <>
              <CreditCardDetails
                cardNumber={transaction.masked_pan}
                cardNumberClassName='card-number'
              />
            </>
          ) : <RepeatIcon />}
        </span>
      )
    } else {
      return null;
    }
  },
  getTransactionAttachmentIcon: ({transaction, ...rest}) => {
    if (transaction && transaction.expense) {
      const onClick = rest.onClick;
      const invoiceStatus = transaction.expense.expense_invoice_status;
      const iconTypes = {
        [expenseInvoicesStatusConstants.CONFIRMED]: <TransactionIcon />,
        [expenseInvoicesStatusConstants.MATCHED]: <TransactionIcon />
      };
      const icon = iconTypes[invoiceStatus] || <TransactionAddIcon />;
      return (
        <span
          className='transaction-icon'
          {...rest}
          onClick={(e) => onClick && onClick({e, transaction})}
        >
          {icon}
        </span>
      );
    } else {
      return null
    }
  },
  getWireDetails: (details) => {
    const gObjProp = (propName) => helpers.getObjProp(details, propName);
    const getFormattedValue = (value) => value ? value.match(/.{1,4}/g).join(' ') : '';

    return {
      bic: getFormattedValue(gObjProp('bic')),
      iban: getFormattedValue(gObjProp('iban'))
    };
  },
  getInvoiceEmail: (settings) => {
    let email = '';
    const gObjProp = (propName) => helpers.getObjProp(settings, propName);

    if (settings) email = gObjProp('invoice_email_alias') || gObjProp('invoice_email');
    return email;
  },
  addFixedDetailsWindowScrollEventListener: ({detailsWindowFixedHeight, isOpenDetails, isFixedDetailsWindow, setIsFixedDetailsWindow}) => {
    const setScroll = (e) => {
      let scrolled = false;
      if (!isOpenDetails) return;
      if (e.target.scrollTop >= detailsWindowFixedHeight) scrolled = true;
      if (isFixedDetailsWindow !== scrolled) setIsFixedDetailsWindow(scrolled);
    };
    document.querySelector('#app-page-container')?.addEventListener('scroll', setScroll);
    return () => {
      document.querySelector('#app-page-container')?.removeEventListener('scroll', setScroll);
    };
  },
  addInvoiceToTransaction: ({
    addInvoiceModalProps,
    formData,
    successCallback,
    errorCallback,
    batchCreateInvoices,
    closeAddInvoiceModal,
    linkExpense,
    onExpenseUpdate
  }) => {
    const transaction = helpers.getObjProp(addInvoiceModalProps, 'transaction');

    if (transaction.expense) {
      const {invoice, file} = formData;
      const {expense} = transaction;
      const handleLinkExpense = (invoiceId) => {
        linkExpense(
          invoiceId,
          {expense_id: transaction.expense.id},
          () => {
            onExpenseUpdate({
              ...expense,
              is_attachment_uploaded: true,
              expense_invoice_status: expenseInvoicesStatusConstants.MATCHED
            }, 'attachment');
            closeAddInvoiceModal();
            successCallback && successCallback(file);
          },
          (resp) => errorCallback && errorCallback(resp)
        )
      }

      if (file) {
        batchCreateInvoices(
          {invoices: [{base64_file: file.file, file_name: file.name}]},
          (invoices) => {
            if (invoices.length > 0) {
              handleLinkExpense(invoices[0].id);
            } else {
              successCallback && successCallback();
            }
          },
          (resp) => errorCallback && errorCallback(resp)
        )
      } else {
        handleLinkExpense(invoice);
      }
    }
  },
  removeInvoiceFromTransaction: ({
    t,
    transaction,
    successCallback,
    errorCallback,
    unlinkExpense,
    onExpenseUpdate
  }) => {
    if (transaction.expense) {
      Modal.confirm({
        title: t('transactions:modal.removeInvoice.title'),
        icon: <ExclamationCircleFilled />,
        content: t('transactions:modal.removeInvoice.description'),
        okText: t('main:yes'),
        okType: 'danger',
        cancelText: t('main:no'),
        cancelButtonProps: {size: 'large'},
        okButtonProps: {size: 'large'},
        onCancel() {
          errorCallback && errorCallback();
        },
        onOk() {
          const {expense} = transaction;
          unlinkExpense(
            {expense_id: expense.id},
            () => {
              onExpenseUpdate({
                ...expense,
                expense_invoice_status: undefined,
                is_attachment_uploaded: false
              }, 'attachment');
              successCallback && successCallback();
            },
            (resp) => errorCallback && errorCallback(resp)
          )
        }
      });
    } else {
      errorCallback && errorCallback();
    }
  },
  getGroupedTransactions: (transactions) => {
    const daysList = {};
    const datePropName = 'created_date';
    const summarizeKeyName = 'isMonthGrouping';
    let groupedTransactions = [];
    let lastAdded;

    // define transactions months
    transactions.forEach(transaction => {
      const date = transaction[datePropName].slice(0, 7);
      const {is_incoming: isIncoming} = transaction;
      const amount = Number(transaction.amount) || 0;
      const income = isIncoming ? amount : 0;
      const outcome = isIncoming ? 0 : amount;
      const isReturnedTransaction = transactionsHelpers.isReturnedTransaction(transaction);
      if (!isReturnedTransaction) {
        if (daysList.hasOwnProperty(date)) {
          daysList[date] = {
            income: daysList[date].income + income,
            outcome: daysList[date].outcome + outcome
          }
        } else {
          daysList[date] = {
            income,
            outcome
          }
        }
      }
    });

    // add grouping rows to transactions list
    transactions.forEach(t => {
      const date = t[datePropName].slice(0, 7);
      if (lastAdded !== date && daysList.hasOwnProperty(date)) {
        lastAdded = date;
        groupedTransactions.push({
          id: date,
          [summarizeKeyName]: true,
          [datePropName]: date,
          ...daysList[date]
        });
      }
      groupedTransactions.push(t);
    });
    groupedTransactions = helpers.additionalTransactionsGrouping(groupedTransactions, summarizeKeyName);
    return groupedTransactions;
  },
  isMonthlyGroupedRow: (row) => row.isMonthGrouping,
  getTransactionsWithExpense: ({employees, expenses, transactions}) => {
    return transactions.map(t => {
      const {expense_id: expenseId, user_id: userId} = t;
      const user = employees.find(e => e.employee_id === userId);
      const expense = expenses.find(e => e.id === expenseId);
      return {
        ...t,
        expense,
        user
      }
    });
  },
  getRowClassName: ({
    groupedPropName,
    index,
    record,
    selectedRowIndex
  }) => {
    let className = '';
    if (record[groupedPropName]) className += ' grouped-row';
    if (index === selectedRowIndex) className += ' selected-row';
    return className;
  }
}
