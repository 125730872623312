import styled from 'styled-components';
import {Button, Space} from 'antd';
import EmptySubscriptions from '../../SubscriptionsPage/EmptySubscriptions';
import {borders, typography} from '../../../../utils/css';

const StyledSubscriptionsDetails = styled(Space)`
  width: 100%;
  
  .ant-card-head {
    min-height: 40px;
  }
  
  .ant-spin-container {
    display: flex;
    flex-direction: column;
  }
`;


const StyledSubscriptionsDetailsCardExtra = styled.div`
  ${typography.footnote.medium};
  color: ${props => props.theme.colors.neutral_6};
`;

const StyledSubscriptionsDetailsMoreButton = styled(Button)`
  ${typography.footnote.description};
  align-items: center;
  color: ${props => props.theme.colors.primary_6};
  display: flex;
  margin-left: auto;
  margin-top: 6px;
  padding: 3px 0;
  
  svg {
    height: 16px;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 16px;
    
    path {
      fill: ${props => props.theme.colors.primary_6};
      stroke: ${props => props.theme.colors.primary_6};
    }
  }
  
  &:not(:disabled):not(.ant-btn-disabled) {
    &:active {
      color: ${props => props.theme.colors.primary_8};
      svg {
        path {
          fill: ${props => props.theme.colors.primary_8};
          stroke: ${props => props.theme.colors.primary_8};
        }
      }
    }
    
    &:hover {
      color: ${props => props.theme.colors.primary_7};
      svg {
        path {
          fill: ${props => props.theme.colors.primary_7};
          stroke: ${props => props.theme.colors.primary_7};
        }
      }
    }
  }
`;

const StyledSubscriptionsDetailsPlaceholder = styled(EmptySubscriptions)`
  ${borders.default};
  
  background-color: ${props => props.theme.colors.neutral_1};
  margin: unset;
  padding: 24px 16px;
  height: calc(100vh - 66px - 24px * 2);
  
  .ant-empty-image {
    margin-bottom: 0;
  }
  
  h3 {
    ${typography.heading.h5};
  }
  
  p {
    ${typography.footnote.description};
    margin-bottom: 16px;
    text-align: center;
  }
  
  button.ant-btn {
    font-size: 14px;
    width: 159px;
  }
`;

const StyledSubscriptionsDetailsOverflowSpace = styled(Space)`
  width: 100%;
  overflow: auto;
  height: calc(100vh - 162px);
`;

export {
  StyledSubscriptionsDetails,
  StyledSubscriptionsDetailsCardExtra,
  StyledSubscriptionsDetailsMoreButton,
  StyledSubscriptionsDetailsPlaceholder,
  StyledSubscriptionsDetailsOverflowSpace
}