import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {PlusOutlined} from '@ant-design/icons';
import {Button, DatePicker, Form, Input, Select, Space} from 'antd';
import useGeoLocation from 'react-ipgeolocation';
import {StyledBankingInfo, StyledTitle, StyledEmptyContainer, StyledEmptySpinContainer} from './StyledBankingInfo';
import SpinSmall from '../../../../SpinSmall';
import {StyledKYCModalTitleNameSpace} from '../../../../quickStart/KYCModal/StyledKYCModal';
import UserInfo from '../../tabComponents/UserInfo';
import DetailsTable from '../../../TransactionsPage/DetailsTable';
import {bankingActions} from '../../../../../state/actions';
import {EmailRegexp, helpers} from '../../../../../helpers';
import {titleOptions} from '../../../CardsPage/cardsOptions';
import PhoneNumberInput from '../../../../PhoneNumberInput';
import AntdCountriesSelect from '../../../../Select/AntdCountriesSelect';
import {bankingUserTypesConstants} from '../../../../../constants';
import {cardsHelpers} from '../../../CardsPage/cardsHelpers';
import {kycHelpers} from '../../../../quickStart/components/KYCModal/kycHelpers';

const {Item} = Form;

const datePickerProps = kycHelpers.getDatePickerProps('birthday');
const countrySelectProps = kycHelpers.getCountrySelectProps();

const {getCountryName} = helpers;

const BankingInfo = ({
  dispatch,
  employeeId,
  email,
  createUser,
  createTaxResidence,
  getTaxResidence,
  getUserDetails,
  legalPerson,
  ...rest
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formValues, setFormValues] = useState({
    birthday: undefined,
    birth_country: undefined,
    firstname: '',
    email: '',
    lastname: '',
    nationality: undefined,
    place_of_birth: '',
    phone: '',
    title: titleOptions[0].value,
    tax_residence: undefined,
    tax_number: '',
  });
  const [defaultPhoneCode, setDefaultPhoneCode] = useState(undefined);
  const [user, setUser] = useState(undefined);
  const [disabledForm, setDisabledForm] = useState(false);
  const [form] = Form.useForm();
  const location = useGeoLocation();
  const [isLocated, setIsLocated] = useState(false);

  const [t] = useTranslation(['main', 'cards', 'profile']);

  const trans = (key) => t(`profile:tabs.bankingInfo.${key}`);

  const {
    label,
    validationPhoneRules,
    validationTaxRules
  } = useMemo(() => kycHelpers.getKYCFormDetails({t, fullFormKey: 'cards:modal.legalRepresentative'}), [t]);

  const placeholder = (key) => t('placeholderTemplate', {name: t(`cards:modal.legalRepresentative.placeholders.${key}`)});

  const updateUserTaxResidenceDetails = (user, taxResidence) => {
    setUser({...user, tax_residence: taxResidence.country, tax_number: taxResidence.tax_payer_id});
  }

  useEffect(() => {
    if (employeeId) {
      getUserDetails(
        employeeId,
        (user) => {
          setUser(user);
          getTaxResidence(
            user.id,
            (taxResidence) => updateUserTaxResidenceDetails(user, taxResidence)
          )
          setLoading(false);
        },
        () => {
          setUser(undefined);
          setLoading(false);
        }
      )
    }
  }, [employeeId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => setFormValues({...formValues, email}), [email]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const {country, isLoading} = location;
    if (country && !isLocated && !isLoading) {
      let countryObj = helpers.getCountryByCode(country);
      let values = {
        ...formValues,
        birth_country: country,
        nationality: country,
        tax_residence: country
      };
      setFormValues(values);
      form.setFieldsValue(values);
      setIsLocated(true);
      setDefaultPhoneCode(countryObj ? countryObj.country_code : undefined)
    }
  }, [location.isLoading, user]); // eslint-disable-line react-hooks/exhaustive-deps

  const requiredMessage = t('validation.fieldIsRequired');

  const lengthFieldMessage = (value, type = 'min') => t(`validation.${type}LengthValidation`, {value});

  const requiredRules = [{required: true, message: requiredMessage}];

  const onEditClick = () => setIsEditMode(true);

  const handleOnCancel = () => setIsEditMode(false);

  const handleOnSave = () => form.submit();

  const getUserProp = (key, defaultValue) => helpers.getObjProp(user, key, defaultValue) || '-';

  const handleSubmit = (fields) => {
    const {birthday} = fields;
    let data = {
      ...fields,
      ...kycHelpers.getUserCompanyFields(legalPerson),
      // additional params
      birthday: birthday ? birthday.format('YYYY-MM-DD') : birthday,
      employee_id: employeeId,
      tos_accepted: true, // I certify that I am only an Italian fiscal resident
      user_type: bankingUserTypesConstants.EMPLOYEE
    };
    setDisabledForm(true);
    createUser(
      data,
      (user) => {
        setUser(user);
        createTaxResidence(
          {
            ...kycHelpers.getTaxResidenceFormData(data),
            user_id: user.id
          },
          (taxResidence) => {
            updateUserTaxResidenceDetails(user, taxResidence);
            setDisabledForm(false);
            setIsEditMode(false);
          },
          (response) => {
            const fields = kycHelpers.getTaxNumberFormErrors(response);
            form.setFields(fields);
            setDisabledForm(false);
          }
        );
      },
      (response) => {
        const fields = cardsHelpers.getBankingResponseErrorFields(response);
        form.setFields(fields);
        setDisabledForm(false);
      }
    );
  }

  const tableData = [
    {
      key: 'email',
      label: t('email'),
      value: getUserProp('email')
    },
    {
      key: 'user-id',
      label: `${t('user')} ID`,
      value: employeeId
    },
    {
      key: 'birthday',
      label: label('dateOfBirth'),
      value: helpers.getDateWithMonth(getUserProp('birthday'))
    },
    {
      key: 'nationality',
      label: label('nationality'),
      value: getCountryName(getUserProp('nationality'))
    },
    {
      key: 'birthCountry',
      label: label('countryOfBirth'),
      value: getCountryName(getUserProp('birth_country'))
    },
    {
      key: 'birthPlace',
      label: label('cityOfBirth'),
      value: getUserProp('place_of_birth')
    },
    {
      key: 'residence',
      label: label('residence'),
      value: `${getUserProp('address1')}, ${getUserProp('city')}, ${getUserProp('postcode')}, ${getCountryName(getUserProp('country'))}`
    },
    {
      key: 'taxResidence',
      label: label('taxResidence'),
      value: getCountryName(getUserProp('tax_residence'))
    },
    {
      key: 'taxNumber',
      label: label('taxNumber'),
      value: getUserProp('tax_number', '-')
    },
  ];

  const title = (
    <StyledTitle>
      <UserInfo className='user-info' />
    </StyledTitle>
  );

  const requiredRulesLength = [
    ...requiredRules,
    {min: 2, message: lengthFieldMessage(2, 'min')},
    {max: 150, message: lengthFieldMessage(150, 'max')}
  ];

  const countryPlaceholder = t('selectCountry');

  return (
    <StyledBankingInfo
      title={title}
      {...rest}
    >
      {!loading ? (
        <>
          {user ? (
            <DetailsTable
              className={isEditMode && 'd-none'}
              data={tableData}
            />
          ) : (
            <StyledEmptyContainer isEditMode={isEditMode}>
              <Space size={4} direction='vertical'>
                <h3>{trans('title')}</h3>
                <p>{trans('description')}</p>
              </Space>
              {isEditMode
                ? (
                  <Space size='middle'>
                    <Button
                      disabled={disabledForm}
                      onClick={handleOnCancel}
                      size='large'
                      type='default'
                    >
                      {t('cancel')}
                    </Button>
                    <Button
                      disabled={disabledForm}
                      onClick={handleOnSave}
                      size='large'
                      type='primary'
                    >
                      {t('save')}
                    </Button>
                  </Space>
                ) : (
                  <Button
                    icon={<PlusOutlined />}
                    onClick={onEditClick}
                    size='large'
                    type='primary'
                  >
                    {t('add')}
                  </Button>
                )}
            </StyledEmptyContainer>
          )}

          <SpinSmall spinning={disabledForm}>
            <Form
              className={!isEditMode && 'd-none'}
              initialValues={formValues}
              form={form}
              layout='vertical'
              onFinish={handleSubmit}
              requiredMark={false}
            >
              <StyledKYCModalTitleNameSpace size={32} align='start'>
                <Item
                  label={label('title')}
                  name='title'
                  required
                >
                  <Select
                    options={titleOptions}
                    size='large'
                  />
                </Item>
                <Item
                  label={label('firstName')}
                  name='firstname'
                  rules={requiredRulesLength}
                  required
                >
                  <Input
                    maxLength={150}
                    placeholder={placeholder('firstName')}
                    size='large'
                  />
                </Item>
              </StyledKYCModalTitleNameSpace>
              <Item
                label={label('lastName')}
                name='lastname'
                rules={requiredRulesLength}
                required
              >
                <Input
                  maxLength={150}
                  placeholder={placeholder('lastName')}
                  size='large'
                />
              </Item>
              <Item
                label={t('email')}
                name='email'
                rules={[
                  ...requiredRules,
                  {pattern: EmailRegexp, message: t('validation.enterValidEmail')}
                ]}
                required
              >
                <Input
                  placeholder={placeholder('email')}
                  size='large'
                  type='email'
                />
              </Item>
              <Item
                label={label('phoneNumber')}
                name='phone'
                rules={validationPhoneRules}
                required
              >
                <PhoneNumberInput
                  placeholder={placeholder('phoneNumber')}
                  phoneCode={defaultPhoneCode}
                  size='large'
                />
              </Item>
              <Item
                label={label('dateOfBirth')}
                name='birthday'
                rules={requiredRules}
                required
              >
                <DatePicker
                  {...datePickerProps}
                  placeholder={placeholder('dateOfBirth')}
                  style={{width: '100%'}}
                />
              </Item>
              <Item
                label={label('countryOfBirth')}
                name='birth_country'
                rules={requiredRules}
                required
              >
                <AntdCountriesSelect
                  {...countrySelectProps}
                  placeholder={countryPlaceholder}
                />
              </Item>
              <Item
                label={label('cityOfBirth')}
                name='place_of_birth'
                rules={requiredRulesLength}
                required
              >
                <Input
                  maxLength={150}
                  placeholder={placeholder('placeOfBirth')}
                  size='large'
                />
              </Item>
              <Item
                label={label('nationality')}
                name='nationality'
                rules={requiredRules}
                required
              >
                <AntdCountriesSelect
                  {...countrySelectProps}
                  placeholder={countryPlaceholder}
                />
              </Item>
              <Item
                label={label('taxResidence')}
                name='tax_residence'
                rules={requiredRules}
                required
              >
                <AntdCountriesSelect
                  {...countrySelectProps}
                  placeholder={placeholder('taxResidence')}
                />
              </Item>
              <Item
                label={label('taxNumber')}
                name='tax_number'
                rules={validationTaxRules}
                required
              >
                <Input
                  placeholder={placeholder('taxNumber')}
                  size='large'
                />
              </Item>
            </Form>
          </SpinSmall>
        </>
      ) : (
        <StyledEmptySpinContainer>
          <SpinSmall />
        </StyledEmptySpinContainer>
      )}
    </StyledBankingInfo>
  );
}

const mapStateToProps = state => {
  const {id: employeeId, email} = state.user.employee;
  const {legalPerson} = state.banking;

  return {
    employeeId,
    email,
    legalPerson,
  }
}

const mapDispatchToProps = {
  createUser: bankingActions.createUser,
  createTaxResidence: bankingActions.createTaxResidence,
  getTaxResidence: bankingActions.getTaxResidence,
  getUserDetails: bankingActions.getUserDetails,
}

export default connect(mapStateToProps, mapDispatchToProps)(BankingInfo);
