import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useLocation} from 'react-router-dom';
import {connect} from 'react-redux';
import {SwitchTransition} from 'react-transition-group';
import {Button, Space} from 'antd';
import {Collapse} from 'react-collapse';
import {authActions, bankingActions} from '../../state/actions';
import dayjs from 'dayjs';
import {helpers} from '../../helpers';
import {bankingDocumentTypes, bankingUserTypesConstants, localStorageKeysConstants} from '../../constants';
import {
  StyledUserInvitePage,
  StyledUserInvitePageResultSpace,
  StyledUserInvitePageLoader,
  StyledUserInvitePageFooter,
  StyledUserInvitePageSteps,
  StyledUserInvitePageHelpText
} from './StyledUserInvitePage';
import PageDocumentDetails from '../../components/PageDocumentDetails/PageDocumentDetails';
import {StyledImageTransition} from '../../components/pages/AuthPage/PageContainer/StyledPageContainer';
import UserDetailsForm from '../../components/pages/UserInvitePage/UserDetailsForm';
import {FadeTransition, PageContainer} from '../../components/pages/AuthPage/PageContainer';
import {ReactComponent as Step1SvgImage} from '../../static/images/pages/user-invite/step-1.svg';
import {ReactComponent as Step2SvgImage} from '../../static/images/pages/user-invite/step-2.svg';
import {ReactComponent as Step3SvgImage} from '../../static/images/pages/user-invite/step-3.svg';
import {ReactComponent as ErrorSvgImage} from '../../static/images/error.svg';
import {ReactComponent as SuccessSvgImage} from '../../static/images/success.svg';
import routes from '../../routes/routes.json';
import UserNationalityTaxDetailsForm
  from '../../components/pages/UserInvitePage/UserNationalityTaxDetailsForm/UserNationalityTaxDetailsForm';
import LegalRepresentativeVerifyIdentity from '../../components/quickStart/components/KYCModal/substeps/LegalRepresentativeVerifyIdentity';
import LegalRepresentativeDocuments from '../../components/quickStart/components/KYCModal/substeps/LegalRepresentativeDocuments';
import {cardsHelpers} from '../../components/pages/CardsPage/cardsHelpers';
import {kycHelpers} from '../../components/quickStart/components/KYCModal/kycHelpers';

const {getObjProp} = helpers;

const {TAX_STATEMENT} = bankingDocumentTypes;

const {LEGAL_REPRESENTATIVE} = bankingUserTypesConstants;

const uboSteps = {
  'user_null': 1,
  'user_created': 3,
  'user_documents_submitted': 5,
  'user_complete': 5
}

const UserInvitePage = ({
  acceptInvitation,
  completeVerification,
  createDocument,
  createTaxResidence,
  getUserState,
  getTaxResidence,
  getUserDocuments,
  storeUboInviteKey,
  updatePublicUser,
  updateDocument,
  updateTaxResidence
}) => {
  const location = useLocation();
  const [pageLoading, setPageLoading] = useState(false);
  const [inviteDetails, setInviteDetails] = useState(null);
  const [step, setStep] = useState(1);
  const [user, setUser] = useState({});
  const [t] = useTranslation(['main', 'userInvite']);
  const [documentType, setDocumentType] = useState(undefined);
  const tPage = (key, props) => t(`userInvite:${key}`, props);
  const [userDetailsFormValues, setUserDetailsFormValues] = useState({});
  const [userNationalityFormValues, setUserNationalityFormValues] = useState({});
  const [isDefinedTaxResidence, setIsDefinedTaxResidence] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [disableNext, setDisableNext] = useState(false);

  const userDetailsFormRef = useRef(null); // step 1
  const userNationalityTaxDetailsFormRef = useRef(null); // step 2
  const documentsFormRef = useRef(null); // step 4

  const finishLoading = () => setPageLoading(false);

  const getInviteProp = (propName) => getObjProp(inviteDetails, propName);

  const enabledBack = useMemo(() => ![1, 5].includes(step), [step]);

  const enabledNext = useMemo(() => ![3, 5].includes(step), [step]);

  const increaseStep = () => setStep(step + 1);

  const decreaseStep = () => setStep(step - 1);

  const setErrorCallbackMessage = (data) => typeof (data.detail) === 'string' && setErrorMessage(data.detail);

  useEffect(() => {
    setPageLoading(true);
    const invitationKey = new URLSearchParams(location.search).get('key');
    if (invitationKey) {
      storeUboInviteKey(invitationKey);
      acceptInvitation(
        (data) => {
          const {invitation, extra_data: extraData, user} = data;
          const {company_id: companyId} = invitation;
          // set company id in Cookies for using in request headers
          localStorage.setItem(localStorageKeysConstants.COMPANY_ID, companyId);
          getUserState(
            (data) => {
              const {state} = data;
              if (state) {
                // display message that link is not available anymore if user state is completed
                if (state === 'user_complete') {
                  finishLoading();
                  return;
                }
                setInviteDetails({...invitation, ...extraData});
                setUser(user);
                setStep(uboSteps[state] || 1);
              }
              finishLoading();
            },
            finishLoading
          )
        },
        finishLoading
      )
    } else {
      finishLoading();
    }
  }, [acceptInvitation, location]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    errorMessage && setErrorMessage(null);
  }, [step]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (step !== 1 && disableNext) {
      setDisableNext(false);
    } else if (step === 1 && user && !helpers.isEmptyObject(user)) {
      setDisableNext(getObjProp(user, 'user_type') === LEGAL_REPRESENTATIVE);
    }
  }, [step, user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!helpers.isEmptyObject(user)) {
      const {birthday} = user;
      let nationalityValues = {
        ...userNationalityFormValues,
        address1: user.address1,
        city: user.city,
        country: user.country || undefined,
        income_range: user.income_range || undefined,
        personal_assets: user.personal_assets || undefined,
        postcode: user.postcode,
        us_person_accepted: !user.not_us_person_accepted,
        tax_number: user.tax_number,
        tax_residence: user.tax_residence || undefined
      }
      setUserDetailsFormValues({
        ...userDetailsFormValues,
        birthday: birthday && birthday !== '0000-00-00' ? dayjs(birthday) : undefined,
        birth_country: user.birth_country || undefined,
        email: user.email,
        firstname: user.firstname,
        lastname: user.lastname,
        nationality: user.nationality || undefined,
        place_of_birth: user.place_of_birth,
        phone: user.phone,
        title: user.title
      });

      // check if the user has already created tax residence
      getTaxResidence(
        (tax) => {
          setIsDefinedTaxResidence(true);
          nationalityValues = {
            ...nationalityValues,
            tax_residence: tax.country,
            tax_number: tax.tax_payer_id
          }
          setUserNationalityFormValues(nationalityValues)
        },
        () => setUserNationalityFormValues(nationalityValues)
      );

      // check if the user has already submitted document
      getUserDocuments(
        (documents) => {
          const documentType = documents.length > 0 ? kycHelpers.getVerifyDocumentType(documents[0].document_type_id) : undefined;
          const userDocuments = documents
            .filter(d => d.id !== TAX_STATEMENT)
            .map(d => ({documentId: d.id, default: d.file_url, uploaded: d.file_url}));
          setDocuments(userDocuments);
          setDocumentType(documentType);
        }
      );
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  if (pageLoading) {
    return <StyledUserInvitePageLoader />;
  }

  const companyName = getObjProp(inviteDetails, 'company_name');

  const userType = getObjProp(user, 'user_type') || undefined;

  const handleUserDetailsSubmit = (fields) => {
    setUserDetailsFormValues(fields);
    increaseStep();
  }

  const handleUserNationalityTaxDetailsSubmit = async (fields, successCallback, errorCallback) => {
    setUserNationalityFormValues(fields);
    const updatedUserData = {
      ...userDetailsFormValues,
      ...fields,
      birthday: userDetailsFormValues.birthday.format('YYYY-MM-DD'),
      us_person_accepted: undefined,
      us_person_declaration: undefined,
      not_us_person_accepted: userDetailsFormValues.us_person_accepted,
      user_type: user.user_type,
    };

    const successFunc = (data) => {
      successCallback && successCallback(data);
      increaseStep();
    }

    updatePublicUser(
      updatedUserData,
      () => {
        // call create tax residence endpoint (or update tax residence);
        const taxResidenceAction = isDefinedTaxResidence ? updateTaxResidence : createTaxResidence;
        taxResidenceAction(
          kycHelpers.getTaxResidenceFormData(updatedUserData),
          async (taxResidence) => {
            if (fields.us_person_declaration) {
              const file = fields.us_person_declaration?.file || undefined;
              if (file) {
                const data = await cardsHelpers.getUploadedFilePayload(null, file.originFileObj, TAX_STATEMENT);
                createDocument(
                  {
                    ...data,
                    residence_id: taxResidence.bank_id
                  },
                  successFunc,
                  () => {
                    let message = t('serverErrorMessage');
                    if (data && data.detail) message = data.detail;
                    errorCallback && errorCallback([]);
                    setErrorMessage(message);
                  }
                );
              }
            } else {
              successFunc();
            }
          },
          (data) => {
            let message = t('serverErrorMessage');
            if (data && data.detail) message = data.detail;
            errorCallback && errorCallback({tax_number: message});
            setErrorMessage(message);
          }
        );
      },
      (data) => {
        const errors = helpers.getFormServerErrorFields({errors: cardsHelpers.getResponseErrors(data)});
        setErrorCallbackMessage(data);
        if (errors.hasOwnProperty('email')) decreaseStep();
        errorCallback && errorCallback(errors);
      }
    )
  }

  const handleDocumentsSubmit = (fields, successCallback, errorCallback) => {
    let updatedDocuments = [];
    let isEmptyForm = Object.keys(fields).filter(key => fields[key] !== undefined).length === 0;
    Object.keys(fields).forEach((key, index) => {
      const value = fields[key];
      const documentId = documents[index] ? documents[index].documentId : undefined;
      const defaultValue = documents[index] ? documents[index].default : undefined;
      updatedDocuments.push({
        documentId,
        default: defaultValue,
        fieldName: key,
        uploaded: value
      });
    });
    setDocuments(updatedDocuments);
    if (isEmptyForm) {
      setErrorMessage(t('validation.pleaseUploadDocument'));
      errorCallback && errorCallback();
      return;
    }

    const documentUploadSuccessFunc = () => {
      completeVerification(
        increaseStep,
        setErrorCallbackMessage
      );
    };

    const documentUploadErrorFunc = ({fieldName, response}) => {
      const errors = kycHelpers.getFileFormErrors({name: fieldName, response, t});
      errorCallback && errorCallback(errors);
    }

    const documentsNeedUpdate = updatedDocuments.filter(document => document.uploaded && typeof document.uploaded !== 'string');

    if (documentsNeedUpdate.length > 0) {
      const documentTypeId = kycHelpers.getDocumentTypeId(documentType);
      documentsNeedUpdate.forEach(async (document, index) => {
        const documentId = document.documentId;
        const data = await cardsHelpers.getUploadedFilePayload(null, document.uploaded.originFileObj, documentTypeId);
        const fieldName = document.fieldName;
        const latest = index === documentsNeedUpdate.length - 1;
        if (documentId) {
          updateDocument(
            documentId,
            data,
            () => latest && documentUploadSuccessFunc(),
            (response) => documentUploadErrorFunc({fieldName, response})
          )
        } else {
          createDocument(
            data,
            () => latest && documentUploadSuccessFunc(),
            (response) => documentUploadErrorFunc({fieldName, response})
          )
        }
      });
    } else {
      documentUploadSuccessFunc();
    }
  }

  const handleSelectDocumentType = ({key}) => {
    setDocumentType(key);
    increaseStep();
  };

  const transitionProps = {
    key: step,
    mountOnEnter: true,
    timeout: 250,
    unmountOnExit: true,
  }

  const stepImage = ({
    1: (
      <div className='image-content-step-1'>
        <Step1SvgImage />
      </div>
    ),
    2: <Step2SvgImage />
  })[step] || <Step3SvgImage />;

  const invitationResult = (variant) => {
    const isSuccess = variant === 'success';
    return (
      <StyledUserInvitePageResultSpace
        align='center'
        direction='vertical'
        variant={variant}
        size='large'
      >
        {isSuccess ? <SuccessSvgImage /> : <ErrorSvgImage />}
        <h3>{tPage(`${variant}Title`)}</h3>
        <p>{tPage(`${variant}Description`)}</p>
        <Link to={routes.overview}>
          <Button
            size='large'
            type='primary'
          >
            {t('goTo', {direction: 'WithLess'})}
          </Button>
        </Link>
      </StyledUserInvitePageResultSpace>
    )
  }

  const steps = <StyledUserInvitePageSteps quantity={4} activeNum={step} />;

  const title = (stepKey) => tPage(`steps.${stepKey}.title`);

  const formContent = ({
    1: (
      <Space
        direction='vertical'
        size='middle'
      >
        <h2>{title('userDetails')}</h2>
        <p>
          {
            tPage(
              userType === LEGAL_REPRESENTATIVE ? 'legalDescription' : 'uboDescription',
              {name: getInviteProp('legal_representative_name'), companyName}
            )
          }
        </p>
        <UserDetailsForm
          defaultFormValues={userDetailsFormValues}
          handleEnableSubmit={(enable) => setDisableNext(!enable)}
          enableConfirmation={userType === LEGAL_REPRESENTATIVE}
          onSubmit={handleUserDetailsSubmit}
          ref={userDetailsFormRef}
        />
      </Space>
    ),
    2: (
      <Space
        direction='vertical'
        size='middle'
      >
        <h2>{title('userNationalityTaxDetails')}</h2>
        <UserNationalityTaxDetailsForm
          defaultFormValues={userNationalityFormValues}
          onSubmit={handleUserNationalityTaxDetailsSubmit}
          userType={userType}
          ref={userNationalityTaxDetailsFormRef}
        />
      </Space>
    ),
    3: (
      <LegalRepresentativeVerifyIdentity onSelect={handleSelectDocumentType} />
    ),
    4: (
      <LegalRepresentativeDocuments
        defaultValues={kycHelpers.getDocumentsFormValues(documents)}
        onSubmit={handleDocumentsSubmit}
        ref={documentsFormRef}
        variant={documentType}
      />
    ),
    5: invitationResult('success')
  })[step] || null;

  const handleBack = () => setStep(step - 1);

  const submitForm = (key) => {
    const formsRef = {
      'documents': documentsFormRef,
      'userDetails': userDetailsFormRef,
      'userNationalityTaxDetails': userNationalityTaxDetailsFormRef,
    }
    const formRef = formsRef[key] || null;
    if (formRef && formRef.current) formRef.current.submit();
  }

  const handleNext = () => {
    const steps = {
      1: () => submitForm('userDetails'),
      2: () => submitForm('userNationalityTaxDetails'),
      4: () => submitForm('documents')
    };
    if (steps.hasOwnProperty(step)) steps[step]();
  }

  const actionsFooter = step !== 5 && (
    <StyledUserInvitePageFooter>
      <Space>
        {enabledNext && (
          <Button
            disabled={disableNext}
            onClick={handleNext}
            size='large'
            type='primary'
          >
            {t(step === 4 ? 'done' : 'next')}
          </Button>
        )}
      </Space>
      {enabledBack && (
        <Button
          onClick={handleBack}
          size='large'
        >
          {t('back')}
        </Button>
      )}
    </StyledUserInvitePageFooter>
  );

  const enabledInviteDetails = inviteDetails !== null;

  return (
    <StyledUserInvitePage className='external-page-with-form'>
      <PageDocumentDetails title={t('main:pageTitles.userInvite')} />
      <PageContainer
        imageContent={
          <StyledImageTransition>
            <SwitchTransition>
              <FadeTransition {...transitionProps}>
                {stepImage}
              </FadeTransition>
            </SwitchTransition>
          </StyledImageTransition>
        }
        formContent={
          <SwitchTransition>
            <FadeTransition {...transitionProps}>
              <div className={`form-content ${(step === 5 || !enabledInviteDetails) && 'flex-center-center'}`}>
                {enabledInviteDetails ? (
                  <>
                    {step !== 5 && steps}
                    {formContent}
                    <Collapse isOpened={Boolean(errorMessage)}>
                      <StyledUserInvitePageHelpText>
                        {errorMessage}
                      </StyledUserInvitePageHelpText>
                    </Collapse>
                    {actionsFooter}
                  </>
                ) : (
                  <>{invitationResult('fail')}</>
                )}
              </div>
            </FadeTransition>
          </SwitchTransition>
        }
      />
    </StyledUserInvitePage>
  );
}

const mapDispatchToProps = {
  acceptInvitation: bankingActions.acceptUserInvitation,
  completeVerification: bankingActions.completePublicUserVerification,
  createDocument: bankingActions.createPublicUserDocument,
  createTaxResidence: bankingActions.createPublicUserTaxResidence,
  getUserDocuments: bankingActions.getPublicUserDocuments,
  getUserState: bankingActions.getPublicUserState,
  getTaxResidence: bankingActions.getPublicUserTaxResidence,
  storeUboInviteKey: authActions.storeUboInviteKey,
  updatePublicUser: bankingActions.updatePublicUser,
  updateTaxResidence: bankingActions.updatePublicUserTaxResidence,
  updateDocument: bankingActions.updatePublicUserDocument,
}

export default connect(null, mapDispatchToProps)(UserInvitePage);
