import styled from 'styled-components';
import {borders} from '../../../../utils/css';

const StyledHeaderDetails = styled.div`
  ${borders.light};
  align-items: self-start;
  background: ${props => props.theme.colors.neutral_1};
  display: flex;
  justify-content: space-between;
  padding: 24px;
  background: linear-gradient(0deg, ${props => props.theme.colors.neutral_1}, ${props => props.theme.colors.neutral_1}),
              linear-gradient(0deg, ${props => props.theme.colors.neutral_3}, ${props => props.theme.colors.neutral_3});
`;

export {
  StyledHeaderDetails
}
