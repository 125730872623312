import React, {useEffect, useMemo, useState} from 'react';
import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {
  StyledSettingPageContainer,
  StyledTabComponentContainer,
  StyledTabs
} from './StyledSettingsPage';
import PageContainer from '../../components/PageContainer';
import SpinSmall from '../../components/SpinSmall';
import CompanyInfo from '../../components/pages/SettingsPage/tabs/CompanyInfo';
import InvoiceRetrieval from '../../components/pages/SettingsPage/tabs/InvoiceRetrieval';
import TagsManagement from '../../components/pages/SettingsPage/tabs/TagsManagement';
import UsageMonitoring from '../../components/pages/SettingsPage/tabs/UsageMonitoring';
import LegalDocuments from '../../components/pages/SettingsPage/tabs/LegalDocuments';
import LegalInformation from '../../components/pages/SettingsPage/tabs/LegalInformation';
import BalancePageHeader from '../../components/pages/TransactionsPage/BalancePageHeader/BalancePageHeader';
import PageDocumentDetails from '../../components/PageDocumentDetails';
import BillingInformation from '../../components/pages/SettingsPage/tabs/BillingInformation';
import BillingDetailsInformation from '../../components/pages/SettingsPage/tabs/BillingDetailsInformation';
import {billingActions, companyActions, serviceStatsActions} from '../../state/actions';
import {helpers} from '../../helpers';
import {transactionsHelpers} from '../../components/pages/TransactionsPage/transactionsHelpers';
import {availableModulesConstants, rewardStatusesConstants} from '../../constants';

const isAvailableSSOModule = helpers.checkIfAvailableFeature(availableModulesConstants.SSO);

const defaultRewardsTotals = {
  alreadyClaimed: 0,
  toBeClaimed: 0,
  total: 0,
  loaded: false
}

const SettingsPage = ({
  companyId,
  getRewardsList,
  getRewardsTotals,
  getTransfersList,
  getSSCompany,
  getSettings,
}) => {
  const location = useLocation();
  const [t] = useTranslation(['settings', 'main']);
  const [tabs, setTabs] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState('overview');
  const [services, setServices] = useState({
    data: [],
    loaded: false
  });
  const [loading, setLoading] = useState(false);
  const [invoiceEmail, setInvoiceEmail] = useState('');
  const [billingInformation, setBillingInformation] = useState({
    initialLoaded: false,
    data: [],
    loading: true,
    pagination: null
  });
  const [rewards, setRewards] = useState({
    data: [],
    loaded: false
  });
  const [rewardsTotals, setRewardsTotals] = useState(defaultRewardsTotals);

  const tabTrans = (key) => t(`tabs.${key}.title`);

  useEffect(() => {
    const hash = helpers.getLocationHash(location);
    if (hash && tabs.length > 0) {
      let searchedIndex = tabs.findIndex(t => t.key === hash)
      if (searchedIndex >= 0) setActiveTabKey(searchedIndex);
    }
  }, [location.hash, tabs]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (companyId) {
      setLoading(true);
      getSettings(
        (settings) => {
          setInvoiceEmail(transactionsHelpers.getInvoiceEmail(settings));
          setLoading(false);
        },
        () => {
          invoiceEmail && setInvoiceEmail('');
          setLoading(false);
        });
    }
  }, [getSettings, companyId]); // eslint-disable-line react-hooks/exhaustive-deps

  const billingData = useMemo(() => {
    return billingInformation.data.map(billingItem => {
      let currentDate = dayjs(billingItem.created_date * 1000);
      const rewardObj = rewards.data.find(j => {
        const objData = dayjs().month(j.month - 1).year(j.year);
        return objData.year() === currentDate.year() && objData.month() === currentDate.month();
      });
      return {
        ...billingItem,
        cashback: rewardObj ? rewardObj.total : undefined
      }
    });
  }, [billingInformation.data, rewards.data]);

  useEffect(() => {
    let tabs = [
      {
        children: (
          <StyledTabComponentContainer
            align='start'
            size='large'
          >
            <CompanyInfo />
            <TagsManagement />
          </StyledTabComponentContainer>
        ),
        key: 'overview',
        label: tabTrans('overview')
      },
      {
        children: (
          <StyledTabComponentContainer
            align='start'
            size='large'
          >
            <InvoiceRetrieval email={invoiceEmail} />
          </StyledTabComponentContainer>
        ),
        key: 'invoiceRetrieval',
        label: tabTrans('invoiceRetrieval')
      },
      {
        children: (
          <StyledTabComponentContainer
            align='start'
            size='large'
          >
            <LegalInformation />
            <LegalDocuments />
          </StyledTabComponentContainer>
        ),
        key: 'legal',
        label: tabTrans('legal')
      },
      {
        children: (
          <StyledTabComponentContainer
            align='start'
            size='large'
          >
            <BillingInformation
              data={billingData}
              loading={billingInformation.loading}
              pagination={billingInformation.pagination}
              handleLoadMore={handleLoadBillingTransfers}
            />
            <SpinSmall spinning={!rewardsTotals.loaded}>
              <BillingDetailsInformation
                handleClaimComplete={() => handleLoadRewards(true)}
                totals={rewardsTotals}
              />
            </SpinSmall>
          </StyledTabComponentContainer>
        ),
        key: 'billing',
        label: tabTrans('billing')
      }
    ];
    if (isAvailableSSOModule) {
      tabs = [
        ...tabs.slice(0, 1),
        {
          children: (
            <StyledTabComponentContainer
              align='start'
              size='large'
            >
              <SpinSmall spinning={!services.loaded}>
                <UsageMonitoring
                  connections={services.data}
                  handleUpdateServices={handleUpdateServices}
                />
              </SpinSmall>
            </StyledTabComponentContainer>
          ),
          key: 'usageMonitoring',
          label: tabTrans('usageMonitoring')
        },
        ...tabs.slice(1)
      ]
    }
    setTabs(tabs);
  }, [billingInformation, services, billingData, rewardsTotals]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleLoadBillingTransfers = () => {
    let cursor = helpers.getObjProp(billingInformation.pagination, 'cursor') || undefined;
    if (billingInformation.initialLoaded && cursor === undefined) return;

    setBillingInformation({
      ...billingInformation,
      loading: true
    });

    getTransfersList({
      query: { cursor },
      successCallback: (data) => {
        setBillingInformation({
          ...billingInformation,
          data: [...billingInformation.data, ...(data.results || [])],
          initialLoaded: true,
          loading: false,
          pagination: data.pagination
        });
      },
      errorCallback: () => {
        setBillingInformation({
          ...billingInformation,
          initialLoaded: true,
          loading: false
        });
      }
    });
  }

  const handleLoadRewards = (forceUpdate = false) => {
    if (forceUpdate || !rewards.loaded) {
      getRewardsList({
        successCallback: (data) => setRewards({data, loaded: true}),
        errorCallback: () => setRewards({data: [], loaded: true})
      });
    }
    if (forceUpdate || !rewardsTotals.loaded) {
      getRewardsTotals({
        successCallback: (data) => {
          const totals = data?.totals || {};
          const getTotalValue = (key) => totals?.[key] || 0;
          const ready = getTotalValue(rewardStatusesConstants.READY);
          const claimed = getTotalValue(rewardStatusesConstants.CLAIMED);
          const pending = getTotalValue(rewardStatusesConstants.PENDING);
          const paid = getTotalValue(rewardStatusesConstants.PAID);
          setRewardsTotals({
            alreadyClaimed: paid + claimed,
            toBeClaimed: ready,
            total: ready + pending + paid + claimed,
            loaded: true
          });
        },
        errorCallback: () => setRewardsTotals({...defaultRewardsTotals, loaded: true})
      });
    }
  }

  const loadBillingDetails = () => {
    handleLoadBillingTransfers();
    handleLoadRewards();
  }

  useEffect(() => {
    const methods = {
      'billing': loadBillingDetails,
      'usageMonitoring': loadSSData
    }
    if (methods.hasOwnProperty(activeTabKey)) methods[activeTabKey]();
  }, [activeTabKey]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadSSData = () => {
    if (companyId && isAvailableSSOModule && !services.loaded) {
      getSSCompany(
        (data) => {
          const services = [];
          Object.keys(data).filter(k => k.includes('_connections')).forEach(key => {
            const name = key.split('_')[0];
            data[key].filter(s => s.is_authorized).forEach(service => {
              services.push({
                connected: true,
                id: service.id,
                domain: service.domain,
                name,
              });
            });
          });
          setServices({loaded: true, data: services});
        },
        () => setServices({loaded: true, data: []})
      );
    }
  }

  const handleUpdateServices = (serviceId) => {
    setServices({
      ...services,
      data: services.data.filter(s => s.id !== serviceId)
    })
  };

  const breadcrumbs = [
    {title: t('title')}
  ];

  return (
    <PageContainer>
      <PageDocumentDetails title={t('main:pageTitles.settings')} />
      <BalancePageHeader breadcrumbs={breadcrumbs} />
      <SpinSmall spinning={loading}>
        <StyledSettingPageContainer>
          <StyledTabs
            activeKey={activeTabKey}
            type='card'
            items={tabs}
            onChange={setActiveTabKey}
          />
        </StyledSettingPageContainer>
      </SpinSmall>
    </PageContainer>
  );
}

const mapStateToProps = state => {
  return {
    companyId: state.company.id
  }
}

const mapDispatchToProps = {
  getSettings: companyActions.getCompanySettings,
  getSSCompany: serviceStatsActions.getSSCompany,
  getTransfersList: billingActions.getTransfersList,
  getRewardsList: billingActions.getRewardsList,
  getRewardsTotals: billingActions.getRewardsTotals
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
