import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Progress} from 'antd';
import {EyeInvisibleOutlined, EyeOutlined} from '@ant-design/icons';
import {ReactComponent as MasterCardLogo} from '../../static/images/pages/cards/mastercard-logo.svg';
import {ReactComponent as WithLessWhiteLogo} from '../../static/images/pages/cards/withless-logo-white.svg';
import {ReactComponent as WithLessDarkLogo} from '../../static/images/pages/cards/withless-logo-dark.svg';
import {helpers} from '../../helpers';
import {cardsHelpers} from '../pages/CardsPage/cardsHelpers';
import {
  StyledCreditCard, StyledAlert, StyledShowButton, StyledCardDetails, StyledCardOwner, StyledCardNumber,
  StyledCardFooter, StyledCVVCode, StyledBlockedWindow, StyledCreditCardContainer, StyledLimit, StyledLimitHelper,
  StyledErrorMessage
} from './StyledCreditCard';
import {firebaseEvents} from '../../snippets/firebase';


const gOP = helpers.getObjProp;

const getCardNumberValue = (value) => value
  ? cardsHelpers.getFormattedCardNumber(value.replace(/.(?=.{4})/g, '*'))
  : '**** **** **** ****';

const CreditCard = ({
  data,
  hold,
  error,
  errorMessage,
  limits,
  noId,
  onMoreClick,
  showMore,
  terminated,
  variant,
  ...rest
}) => {
  const [t] = useTranslation(['cards', 'main']);
  const [isHidden, setIsHidden] = useState(true);

  const enableLimit = useMemo(() => !terminated && !error, [error, terminated]);

  const CardLogo = useMemo(() => variant === 'light' ? WithLessDarkLogo : WithLessWhiteLogo, [variant]);

  const getCardNumber = (isPlaceholder) => {
    const value = isPlaceholder ? '' : gOP(data, 'number');
    const number = getCardNumberValue(value);
    return number ? number.split(' ').map((n, index) => (
      <span className={(index === 3 && n !== '****' && !isPlaceholder) ? 'top' : ''} key={index}>
        {n}
      </span>
    )) : number;
  }

  const getMoneyView = (value, showFractionalPart) => helpers.getMoneyView(value, undefined, undefined, true, showFractionalPart);

  const getLimitDetails = () => {
    let showFractionalPart;
    let percent = 100;
    let used = 0;
    let total = 0;
    if (limits) {
      total = limits?.total || 0;
      used = limits?.used || 0;
      percent = used !== 0 ? (used / total) * 100 : 0;
    }
    showFractionalPart = total % 1 !== 0 || used % 1 !== 0;
    return {
      percent,
      total: getMoneyView(total, showFractionalPart),
      used: getMoneyView(used, showFractionalPart)
    };
  }

  const handleShowDetails = () => setIsHidden(false);

  const handleDetailsClick = async () => {
    if (onMoreClick) {
      if (isHidden) {
        onMoreClick(
          handleShowDetails,
          handleShowDetails
        );
        helpers.logEvent(firebaseEvents.VIEW_CARD_DETAILS);
      } else {
        setIsHidden(true);
      }
    }
  };

  const owner = data.embossedName;
  const cardWithError = [error, hold, noId, terminated].some(v => v === true);
  const className = enableLimit ? 'enable-limit' : '';

  const getAlertMessage = () => {
    let message;
    if (error) {
      message = t('errors.cardError');
    } else if (terminated) {
      message = t('statuses.terminated');
    } else if (noId) {
      message = t('errors.noId');
    } else if (hold) {
      message = t('errors.hold');
    }
    return message;
  }

  const alertMessage = errorMessage || getAlertMessage();

  const {percent, total, used} = getLimitDetails();

  const hideCardDetails = (cardWithError && (!noId && !hold)) || error;

  const isPlaceholder = noId || hold;

  const cardNumber = getCardNumber(isPlaceholder);

  return (
    <StyledCreditCardContainer {...rest}>
      <StyledCreditCard
        className={className}
        error={hideCardDetails || hold}
        variant={variant}
      >
        {!cardWithError && showMore && (
          <StyledShowButton
            icon={isHidden ? <EyeOutlined /> : <EyeInvisibleOutlined />}
            onClick={handleDetailsClick}
            shape='circle'
            size='large'
          />
        )}
        {cardWithError && (
          <>
            <StyledBlockedWindow
              className={className}
              fullBordered={hold}
              isPlaceholder={isPlaceholder}
            />
            <StyledAlert
              message={alertMessage}
              type='warning'
              showIcon
            />
          </>
        )}

        <StyledCardDetails>
          <CardLogo className='card-logo' />

          {hideCardDetails ? (
            <>
              {terminated && (
                <>
                  <StyledCardOwner>
                    {owner}
                  </StyledCardOwner>
                  <StyledCardNumber>
                    {cardNumber}
                  </StyledCardNumber>
                </>
              )}
              {error && (
                <StyledErrorMessage>
                  {t('main:error')}
                </StyledErrorMessage>
              )}
            </>
          ) : (
            <>
              {isPlaceholder ? (
                <StyledCardNumber isPlaceholder={isPlaceholder}>
                  {cardNumber}
                </StyledCardNumber>
              ) : (
                <>
                  <StyledCardOwner>
                    {owner}
                  </StyledCardOwner>
                  <StyledCardNumber>
                    {cardNumber}
                  </StyledCardNumber>
                  <StyledCardFooter>
                    <StyledCVVCode>
                      <span>**/**</span>
                      {t('cvv')}
                    </StyledCVVCode>
                    <MasterCardLogo />
                  </StyledCardFooter>
                </>
              )}
            </>
          )}
        </StyledCardDetails>
      </StyledCreditCard>

      {enableLimit && (
        <StyledLimit>
          <Progress
            percent={percent}
            size='small'
            showInfo={false}
            status={percent > 100 ? 'exception' : 'success'}
          />
          <StyledLimitHelper>
            <span>{used}</span>
            <span>{total}</span>
          </StyledLimitHelper>
        </StyledLimit>
      )}

    </StyledCreditCardContainer>
  )
}

CreditCard.propTypes = {
  data: PropTypes.shape({
    cvv: PropTypes.string.isRequired,
    embossedName: PropTypes.string.isRequired,
    expiration: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    pin: PropTypes.string.isRequired,
  }).isRequired,
  hold: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.any,
  limits: PropTypes.shape({
    limit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    used: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }),
  noId: PropTypes.bool,
  onMoreClick: PropTypes.func,
  showMore: PropTypes.bool,
  terminated: PropTypes.bool,
  variant: PropTypes.oneOf(['light', 'dark'])
}

CreditCard.defaultProps = {
  hold: false,
  error: false,
  noId: false,
  showMore: true,
  terminated: false,
  variant: 'dark'
}

export default CreditCard;
