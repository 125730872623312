import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Result} from 'antd';
import CodeAuth from '../../../CodeAuth';
import {useIsMount} from '../../../../hooks';
import {companyActions, userActions} from '../../../../state/actions';
import {helpers} from '../../../../helpers';
import {localStorageKeysConstants} from '../../../../constants';
import {
  StyledSmsAuthModalContainer,
  StyledSmsAuthModalSuccessContainer,
  StyledSmsAuthModal
} from './StyledSmsAuthModal';
import {scaHelpers} from '../../../../scaHelpers';
import {firebaseEvents} from '../../../../snippets/firebase';

let interval = null;
const defaultVerificationDetail = {sessionId: null, errors: null};
const {getVisibleClassName} = helpers;

const logEvent = (status) => helpers.logEvent(firebaseEvents.USER_CODE_AUTH, {status});

const SmsAuthModal = ({
  open,
  handleCancel,
  phoneNumber,
  onSuccess,
  initialSeconds,
  startPhoneVerification,
  endPhoneVerification,
  successWindowDelay,
  title,
  operationName
}) => {
  const [t] = useTranslation(['main', 'cards']);
  const [seconds, setSeconds] = useState(initialSeconds);
  const [verificationDetails, setVerificationDetails] = useState(defaultVerificationDetail);
  const [isDisabled, setIsDisabled] = useState(false);
  const [canStartCount, setCanStartCount] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const isMounted = !useIsMount();

  const trans = (key) => t(`cards:modal.2fa.${key}`);

  const finishLoading = () => setIsDisabled(false);

  const handleStartVerification = () => {
    setIsDisabled(true);
    setCanStartCount(false);
    startPhoneVerification({
      data: {
        sca_session_type: scaHelpers.getOperationType(operationName)
      },
      successCallback: (data) => {
        setSeconds(initialSeconds);
        finishLoading();
        setVerificationDetails({...verificationDetails, sessionId: data.session_id, errors: null});
        setCanStartCount(true);
      },
      errorCallback: finishLoading
    });
  }

  useEffect(() => {
    if (isMounted && !open) {
      setSeconds(initialSeconds);
      setVerificationDetails(defaultVerificationDetail);
      setIsCompleted(false);
    }
    if (open) {
      handleStartVerification();
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  const startTimer = () => {
    interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);
  };

  useEffect(() => {
    if (seconds > 0 && open) {
      startTimer();
      return () => {
        if (interval) clearInterval(interval);
      }
    } else if (seconds <= 0) {
      // handleClose && handleClose();
    }
  }, [seconds, open]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSuccessValidate = (data) => {
    setIsDisabled(true);
    endPhoneVerification(
      {code: data.code, session_id: verificationDetails.sessionId},
      (data) => {
        setIsCompleted(true);
        finishLoading();
        setTimeout(() => {
          localStorage.setItem(localStorageKeysConstants.TWO_FA_STARTED, String(Date.now()));
          onSuccess && onSuccess(data.session_key);
        }, [successWindowDelay]);
        logEvent('success');
      },
      (errors) => {
        setVerificationDetails({...verificationDetails, errors});
        finishLoading();
        logEvent('error');
      }
    );
  }

  const onCodeKeyChange = () => {
    if (verificationDetails.errors) setVerificationDetails({...verificationDetails, errors: null});
  }

  return (
    <StyledSmsAuthModal
      className={isDisabled && 'disabled'}
      footer={false}
      onCancel={handleCancel}
      open={open}
      title={title || trans('enterCode')}
      zIndex={1001}
    >
      <StyledSmsAuthModalContainer className={getVisibleClassName('', !isCompleted)}>
        <h3>{trans('enterCode')}</h3>
        <p className='text-center'>
          {trans('pleaseTypeCode')}
          <br />
          <b>{phoneNumber}</b>
        </p>
        {open && (
          <CodeAuth
            autofocus={true}
            numberCount={6}
            onSuccess={handleSuccessValidate}
            onResend={handleStartVerification}
            canStartCount={canStartCount}
            additionalErrors={verificationDetails.errors}
            onKeyChange={onCodeKeyChange}
          />
        )}
      </StyledSmsAuthModalContainer>
      <StyledSmsAuthModalSuccessContainer className={getVisibleClassName('', isCompleted)}>
        <Result
          status='success'
          title={trans('completedSuccessfully')}
        />
      </StyledSmsAuthModalSuccessContainer>
    </StyledSmsAuthModal>
  );
}

SmsAuthModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  initialSeconds: PropTypes.number,
  operationName: PropTypes.string,
  successWindowDelay: PropTypes.number,
  title: PropTypes.string
}

SmsAuthModal.defaultProps = {
  initialSeconds: 59,
  successWindowDelay: 1000
}

const mapStateToProps = state => {
  const {phone_number: phoneNumber} = state.user.user;
  return {
    phoneNumber
  }
}

const mapDispatchToProps = {
  startPhoneVerification: userActions.startPhoneVerification,
  endPhoneVerification: userActions.endPhoneVerification,
  getCompanyInformation: companyActions.getCompanyInformation
};

export default connect(mapStateToProps, mapDispatchToProps)(SmsAuthModal);
