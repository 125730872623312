import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {StyledSubscriptionPage} from './StyledSubscriptioPage';
import SpinSmall from '../../components/SpinSmall';
import BalancePageHeader from '../../components/pages/TransactionsPage/BalancePageHeader';
import SubscriptionTabs from '../../components/pages/SubscriptionsPage/SubscriptionTabs';
import EmptySubscriptions from '../../components/pages/SubscriptionsPage/EmptySubscriptions';
import PageDocumentDetails from '../../components/PageDocumentDetails';
import PageContainer from '../../components/PageContainer';
import {companyActions, mainActions, subscriptionActions} from '../../state/actions';
import {helpers} from '../../helpers';
import {cardsHelpers} from '../../components/pages/CardsPage/cardsHelpers';
import useIsEmployeeLoaded from '../../hooks/useIsEmployeeLoaded';

const gObjProp = helpers.getObjProp;

const SubscriptionsPage = ({
  isRequiredCardVerification,

  getSubscriptionsTotal,
  getSubscriptionsViewsStatuses,
  getEmployees,
  getTagList,
  openLightKycWindow,
}) => {
  const [t] = useTranslation(['subscriptions', 'main']);
  const [total, setTotal] = useState(null);
  const [loading, setLoading] = useState(true);

  const isEmployeeLoaded = useIsEmployeeLoaded();

  useEffect(() => getTagList(), [getTagList]);

  useEffect(() => isEmployeeLoaded && getEmployees(), [isEmployeeLoaded, getEmployees]);

  useEffect(() => getSubscriptionsViewsStatuses(), [getSubscriptionsViewsStatuses]);

  useEffect(() => {
    handleUpdateSubscriptionTotal();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const finishLoading = () => loading && setLoading(false);

  const handleUpdateSubscriptionTotal = () => {
    getSubscriptionsTotal(
      (data) => {
        setTotal({
          authorized: gObjProp(data, 'total_subscriptions_authorized', 0),
          deleted: gObjProp(data, 'total_canceled', 0),
          need_review: gObjProp(data, 'total_audited', 0),
          restricted: gObjProp(data, 'total_restricted', 0),
          unmanaged: gObjProp(data, 'total_unmanaged', 0),
          all: gObjProp(data, 'total_subscriptions_quantity', 0)
        });
        finishLoading();
      },
      finishLoading
    );
  }

  return (
    <PageContainer>
      <PageDocumentDetails title={t('main:pageTitles.subscriptionsList')} />
      <BalancePageHeader breadcrumbs={[{title: t('subscriptions')}]} />

      <SpinSmall spinning={total === null && loading}>
        <StyledSubscriptionPage>
          {isRequiredCardVerification ? (
            <EmptySubscriptions
              enableButton={true}
              onClick={openLightKycWindow}
              variant='banking'
            />
          ) : (
            <SubscriptionTabs
              handleUpdateSubscriptionTotal={handleUpdateSubscriptionTotal}
              total={total}
              totalLoading={loading}
            />
          )}
        </StyledSubscriptionPage>
      </SpinSmall>
    </PageContainer>
  );
}

const mapStateToProps = state => {
  return {
    isRequiredCardVerification: cardsHelpers.checkIsRequiredVerification(state.banking.verification)
  }
}


const mapDispatchToProps = {
  getSubscriptionsTotal: subscriptionActions.getSubscriptionsTotal,
  getSubscriptionsViewsStatuses: subscriptionActions.getSubscriptionsViewsStatuses,
  getEmployees: companyActions.getEmployees,
  getTagList: companyActions.getTags,
  openLightKycWindow: mainActions.openLightKycWindow
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionsPage);
