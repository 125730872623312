import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Avatar, Button, Form, Input, Select, Space, Switch, Tooltip} from 'antd';
import {connect} from 'react-redux';
import {Collapse} from 'react-collapse';
import {InfoCircleOutlined} from '@ant-design/icons';
import {
  StyledCardDetailsPanel,
  StyledCardDetailsPanelAction,
  StyledCardDetailsPanelActionsSpace,
  StyledCardDetailsPanelCard,
  StyledCardDetailsPanelInput,
  StyledCardDetailsPanelSwitch
} from './StyledCardDetailsPanel';
import {
  BillOptions,
  CardLimitPeriodTypeOptions,
  subscriptionsHelpers
} from '../../SubscriptionsPage/subscriptionsHelpers';
import {
  StyledBudgetDetailsFormSpace
} from '../../SubscriptionPage/tabComponents/Overview/BudgetDetails/StyledBudgetDetails';
import {StyledAddNewCardFormModalSwitchSpace} from '../../CardsPage/AddNewCardFormModal/StyledAddNewCardFormModal';
import {StyledCardPaymentTypeCardIconSpace} from '../../CardsPage/CardPaymentType/StyledCardPaymentType';
import EditButton from '../../../EditButton';
import SpinSmall from '../../../SpinSmall';
import IncreaseLimitAlert from '../../SubscriptionsPage/IncreaseLimitAlert';
import DetailsTable from '../../TransactionsPage/DetailsTable';
import CardDetails from '../../SubscriptionPage/tabComponents/Overview/CardDetails';
import CardBanner from '../../CardsPage/CardBanner';
import {EuroIcon, ExternalLinkIcon, TrashIcon, WithLessCreditCardIcon} from '../../../../icons';
import {helpers} from '../../../../helpers';
import {cardsHelpers} from '../../CardsPage/cardsHelpers';
import {
  subscriptionFormFields,
  subscriptionFormValues,
  cardStatusesConstants
} from '../../../../constants';
import routes from '../../../../routes/routes.json';
import {
  StyledTransactionDetailsFormLink
} from "../../TransactionsPage/TransactionDetailsForm/StyledTransactionDetailsForm";

const {
  budgetLimitFieldName,
  billedFieldName,
  cardLimitFieldName,
  limitPeriodShortFieldName
} = subscriptionFormFields;

const {
  defaultBilled,
  defaultCardLimitPeriod
} = subscriptionFormValues;

const {getObjProp: gObjProp, getMoneyView} = helpers;

const {getCardLimitTypeFromBilled} = cardsHelpers;

const {Item} = Form;

const {ACTIVE, CARD_ERROR, FROZEN, PENDING, TERMINATED} = cardStatusesConstants;

const CardDetailsPanel = ({
  cardDetails,
  cardHold,
  cardHoldMessage,
  cardLimits,
  className,
  dispatch,
  isAdmin,
  tags,
  onFormSubmit,
  onDelete,
  onPause,
  isOpenAuthModal,
  subscription,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'cards', 'subscriptions']);
  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = Form.useForm();
  const [initialFormValues, setInitialFormValues] = useState({
    [billedFieldName]: defaultBilled,
    [budgetLimitFieldName]: '',
    [cardLimitFieldName]: '',
    [limitPeriodShortFieldName]: defaultCardLimitPeriod,
    cost_centers: [],
    name: ''
  });
  const [switchProps, setSwitchProps] = useState({checked: false, loading: false});
  const [billOptions, ] = useState(BillOptions());
  const [cardLimitPeriodTypeOptions] = useState(CardLimitPeriodTypeOptions());
  const [isFormLoading, setIsFormLoading] = useState(false);

  const isForPersonalUseCard = useMemo(() => helpers.isNaV(cardDetails?.cardData?.subscription_id), [cardDetails]);

  const tagOptions = useMemo(() => (tags || []).map(tag => {
    const {tag_name: name} = tag;
    return {
      label: name,
      value: name
    }
  }), [tags]); // eslint-disable-line react-hooks/exhaustive-deps

  const trans = (key) => t(`cards:${key}`);

  const tooltipT = (key) => trans(`tooltips.${key}`);

  const gDataProp = (subscription, key, defaultValue) => gObjProp(subscription, key, defaultValue);

  const gCardProp = (key) => gDataProp(cardDetails.cardData, key);

  const cardId = gDataProp(cardDetails, 'card_id', null);
  const cardOwner = gCardProp('owner') || null;

  const [isSelectedHardLimit, setIsSelectedHardLimit] = useState(false);

  const billedLimitPeriod = Form.useWatch(billedFieldName, {form});
  const budgetLimit = Form.useWatch(budgetLimitFieldName, {form});
  const cardLimit = Form.useWatch(cardLimitFieldName, {form});
  const cardLimitPeriod = Form.useWatch(limitPeriodShortFieldName, {form});

  const requiredRules = [{required: true, message: t('validation.fieldIsRequired')}];

  const {
    enableChangeCardAction,
    isCardError,
    isKnownStatusCode,
    isPending,
    isTerminatedCard
  } = useMemo(() => {
    const isKnownStatusCode = cardDetails?.bankingData?.status_code !== undefined;

    const gBankingCardProp = (key) => gDataProp(cardDetails.bankingData, key);

    // enable edit if card status is not deleted/terminated/restricted/unmanaged
    const statusCode = isKnownStatusCode ? cardsHelpers.getStatus(
      gBankingCardProp('status_code'),
      gBankingCardProp('is_live'),
      gBankingCardProp('has_no_id')
    ) : undefined;
    const isPending = isKnownStatusCode && statusCode === PENDING;
    const isCardError = isKnownStatusCode && statusCode === CARD_ERROR;
    const isTerminatedCard = isKnownStatusCode && statusCode === TERMINATED;
    const enableChangeCardAction = [ACTIVE, FROZEN].includes(statusCode);
    return {
      enableChangeCardAction,
      isCardError,
      isKnownStatusCode,
      isPending,
      isTerminatedCard
    }
  }, [cardDetails.bankingData]);

  const isHoldCard = cardHold || (cardId === null && isPending);

  const isEnabledEdit = isAdmin && !isHoldCard;

  const isEnableWalletBanner = useMemo(() => {
    let enable = false;
    if (isKnownStatusCode) {
      enable = isForPersonalUseCard ? !cardsHelpers.isDigitalizedCard(cardDetails?.cardData) : false;
    }
    return enable;
  }, [cardDetails?.cardData, isForPersonalUseCard, isKnownStatusCode]);

  const personalCardLimit = useMemo(() => {
    return cardLimits[isForPersonalUseCard ? 'personalCardLimit' : 'subscriptionCardLimit'] || 10000
  }, [cardLimits, isForPersonalUseCard]);

  const isTooHighLimit = useMemo(() => {
    let isHigh = false;
    if (isEditMode && cardLimit) {
      isHigh = cardsHelpers.isTooHighLimit({
        limit: cardLimit,
        maxLimit: personalCardLimit,
        period: cardLimitPeriod
      });
    }
    return isHigh;
  }, [isEditMode, cardLimit, cardLimitPeriod, personalCardLimit]);

  useEffect(() => {
    if (isSelectedHardLimit && isEditMode && budgetLimit !== cardLimit) {
      form.setFieldValue(cardLimitFieldName, budgetLimit);
    }
  }, [budgetLimit]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isSelectedHardLimit && isEditMode) {
      form.setFieldValue(limitPeriodShortFieldName, cardsHelpers.getCardLimitTypeFromBilled(billedLimitPeriod));
    }
  }, [billedLimitPeriod]); // eslint-disable-line react-hooks/exhaustive-deps

  const disabledSaveButton = useMemo(() => isTooHighLimit || isFormLoading, [isFormLoading, isTooHighLimit]);

  useEffect(() => {
    const gCardProp = (key) => gDataProp(cardDetails.cardData, key);
    const billedPeriodValue = gCardProp(billedFieldName);
    const budgetLimitValue = gCardProp(budgetLimitFieldName);
    const cardLimitValue = gCardProp(cardLimitFieldName);
    const limitPeriodValue = gCardProp(limitPeriodShortFieldName);
    const fieldValues = {
      ...initialFormValues,
      [billedFieldName]: billedPeriodValue,
      [budgetLimitFieldName]: budgetLimitValue,
      [cardLimitFieldName]: cardLimitValue,
      [limitPeriodShortFieldName]: limitPeriodValue,
      cost_centers: gCardProp('cost_centers') || [],
      name: gCardProp('name')
    };
    const isSelectedHardLimit = budgetLimitValue === cardLimitValue && limitPeriodValue === getCardLimitTypeFromBilled(billedPeriodValue);
    setInitialFormValues(fieldValues);
    form.setFieldsValue(fieldValues);
    setIsSelectedHardLimit(isSelectedHardLimit);
    isEditMode && setIsEditMode(false);
  }, [cardDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSwitchProps({
      checked: gDataProp(cardDetails?.cardData, 'lock_status') === cardStatusesConstants.ACTIVE,
      loading: false
    });
  }, [cardDetails]);

  useEffect(() => {
    if (!isOpenAuthModal && switchProps.loading) setSwitchProps({...switchProps, loading: false});
  }, [isOpenAuthModal]); // eslint-disable-line react-hooks/exhaustive-deps

  const cardOwnerAvatarData = {
    label: gObjProp(cardOwner, 'full_name'),
    src: gObjProp(cardOwner, 'logo') || undefined
  }

  const onChangeCardStatus = (checked) => {
    if (onPause) {
      setSwitchProps({...switchProps, loading: true});
      onPause(
        checked,
        () => setSwitchProps({...switchProps, loading: false})
      );
    }
  }

  const statusChangeSwitch = (
    <StyledCardDetailsPanelSwitch
      {...switchProps}
      onChange={onChangeCardStatus}
    />
  );

  const statusChangeSwitchLabel = t('active');

  const getSubscriptionDetails = () => {
    const getServiceProp = (key) => helpers.getObjProp(subscription?.service, key);

    const logo = getServiceProp('logo') || getServiceProp('logo_url');
    const name = subscriptionsHelpers.getSubscriptionName(subscription);

    return (
      <Space align='center' size={4}>
        <Avatar src={logo}>
          {helpers.getInitials(name)}
        </Avatar>
        <StyledTransactionDetailsFormLink to={`${routes.subscriptionsList}/${subscription?.id}`}>
          <Space size='small'>
            {name}
            <span className='link-icon'>
              <ExternalLinkIcon />
            </span>
          </Space>
        </StyledTransactionDetailsFormLink>
      </Space>
    );
  }

  const subscriptionDetails = getSubscriptionDetails();

  const getTableData = () => {
    const onlyAdminAvailableFields = ['active', 'budget', 'owner'];
    let data = [
      {
        key: 'cardName',
        label: trans('cardName'),
        value: gCardProp('name')
      },
      {
        key: 'cardType',
        label: trans('cardType'),
        value: (
          <StyledCardPaymentTypeCardIconSpace
            className={isForPersonalUseCard ? 'for-personal' : 'for-subscription'}
            align='center'
            size='small'
          >
            {t(`cardTypes.${isForPersonalUseCard ? 'forPersonalUse' : 'forSubscriptions'}`)}
            <WithLessCreditCardIcon />
          </StyledCardPaymentTypeCardIconSpace>
        )
      },
      {
        key: 'owner',
        label: `${t('card')} ${t('Owner')}`,
        value: (
          <Space>
            {cardOwnerAvatarData.label}
            <Avatar src={cardOwnerAvatarData.src}>
              {helpers.getInitials(cardOwnerAvatarData.label)}
            </Avatar>
          </Space>
        )
      },
      !isForPersonalUseCard && {
        key: 'subscription',
        label: t('subscriptions:subscription'),
        value: subscriptionDetails
      },
      {
        key: 'budget',
        label: t('Budget'),
        value: subscriptionsHelpers.getBudgetBilledValue({t, data: cardDetails.cardData})
      },
      {
        key: 'costCentre',
        label: t('costCentre'),
        value: (gCardProp('cost_centers') || []).join(', ')
      },
      {
        key: 'cardLimit',
        label: t('subscriptions:cardLimit'),
        value: <>{getMoneyView(gCardProp(cardLimitFieldName))} {t(subscriptionsHelpers.getCardLimitType(gCardProp('limit_period')))}</>
      },
      (!isTerminatedCard && !isHoldCard && isAdmin) && {
        key: 'active',
        label: statusChangeSwitchLabel,
        value: statusChangeSwitch
      }
    ].filter(d => d);
    if (!isAdmin) data = data.filter(i => !onlyAdminAvailableFields.includes(i.key));
    return data;
  }

  const handleEdit = () => enableChangeCardAction && setIsEditMode(true);

  const handleOnCancel = () => {
    setIsEditMode(false);
    setIsFormLoading(false);
  }

  const handleOnSave = () => !disabledSaveButton && form.submit();

  const handleSubmit = (data) => {
    if (onFormSubmit) {
      setIsFormLoading(true);
      onFormSubmit({
        data,
        successCallback: handleOnCancel,
        errorCallback: () => setIsFormLoading(false)
      });
    } else {
      handleOnCancel();
    }
  }

  const handleChangeHardLimit = (value) => {
    setIsSelectedHardLimit(value);
    if (value) {
      form.setFieldsValue({
        [cardLimitFieldName]: budgetLimit,
        [limitPeriodShortFieldName]: getCardLimitTypeFromBilled(billedLimitPeriod)
      })
    }
  }

  const tableData = getTableData();

  const error = cardId === null && isCardError && !isHoldCard;

  const extra = (!error && isEnabledEdit) && (
    <>
      {isEditMode ? (
        <Space size='middle'>
          <Button
            onClick={handleOnCancel}
          >
            {t('cancel')}
          </Button>
          <Button
            disabled={disabledSaveButton}
            onClick={handleOnSave}
            type='primary'
          >
            {t('save')}
          </Button>
        </Space>
      ) : (
        <EditButton
          onClick={handleEdit}
        />
      )}
    </>
  );

  const isOpenedDetailsTable = isEditMode ? false : !isEditMode && (!isTerminatedCard);

  const budgetFieldRules = subscriptionsHelpers.getBudgetFieldRules(t);

  return (
    <StyledCardDetailsPanel
      {...rest}
    >
      <StyledCardDetailsPanelCard
        extra={extra}
        title={t('cardDetail')}
      >
        <SpinSmall spinning={cardDetails.loading}>
          <CardDetails
            className='card-details'
            cardDetails={cardDetails.bankingData}
            cardErrorMessage={cardHoldMessage}
            cardVariant={isForPersonalUseCard ? 'light' : 'dark'}
            error={error}
            hold={isHoldCard}
          />
        </SpinSmall>

        <Collapse isOpened={isOpenedDetailsTable}>
          {isEnableWalletBanner && <CardBanner />}
          <DetailsTable data={tableData}/>
        </Collapse>

        <Collapse isOpened={isEditMode}>
          <SpinSmall spinning={isFormLoading}>
            <Form
              className='pt-20'
              initialValues={initialFormValues}
              form={form}
              layout='vertical'
              onFinish={handleSubmit}
              requiredMark={false}
            >
              <Item
                label={trans('cardName')}
                name='name'
                required
              >
                <Input size='large' />
              </Item>
              <Item
                label={t('costCentre')}
                name='cost_centers'
              >
                <Select
                  size='large'
                  options={tagOptions}
                  mode='multiple'
                />
              </Item>
              <StyledBudgetDetailsFormSpace
                align='start'
                size='middle'
              >
                <Item
                  label={t('Budget')}
                  name={budgetLimitFieldName}
                  rules={budgetFieldRules}
                  required
                >
                  <StyledCardDetailsPanelInput
                    addonBefore={<EuroIcon />}
                    size='large'
                    type='number'
                  />
                </Item>
                <Item
                  label=' '
                  name={billedFieldName}
                  rules={requiredRules}
                >
                  <Select
                    options={billOptions}
                    size='large'
                  />
                </Item>
              </StyledBudgetDetailsFormSpace>
              <Item>
                <StyledAddNewCardFormModalSwitchSpace align='center' size={10}>
                  <Switch checked={isSelectedHardLimit} onChange={handleChangeHardLimit} />
                  <span>{trans('hardLimit')}</span>
                  <Tooltip
                    overlayStyle={{maxWidth: 368}}
                    title={<>{tooltipT('hardCardLimit1')}<br/>{tooltipT('hardCardLimit2')}</>}
                  >
                    <InfoCircleOutlined
                      size={14}
                    />
                  </Tooltip>
                </StyledAddNewCardFormModalSwitchSpace>
              </Item>
              <StyledBudgetDetailsFormSpace
                align='start'
                size='middle'
              >
                <Item
                  label={`${t('card')} ${t('limit')}`}
                  name={cardLimitFieldName}
                  rules={budgetFieldRules}
                  required
                >
                  <StyledCardDetailsPanelInput
                    addonBefore={<EuroIcon />}
                    disabled={isSelectedHardLimit}
                    size='large'
                    type='number'
                  />
                </Item>
                <Item
                  label=' '
                  name={limitPeriodShortFieldName}
                  rules={requiredRules}
                >
                  <Select
                    disabled={isSelectedHardLimit}
                    options={cardLimitPeriodTypeOptions}
                    size='large'
                  />
                </Item>
              </StyledBudgetDetailsFormSpace>
              <Collapse isOpened={isTooHighLimit}>
                <IncreaseLimitAlert
                  limit={personalCardLimit}
                />
              </Collapse>
              <StyledCardDetailsPanelActionsSpace size={48}>
                <StyledCardDetailsPanelAction>
                  <span>{statusChangeSwitchLabel}</span>
                  {statusChangeSwitch}
                </StyledCardDetailsPanelAction>
                <StyledCardDetailsPanelAction className='delete-button'>
                  <span>{t('delete')} {t('card').toLocaleLowerCase()}</span>
                  <div onClick={(e) => onDelete && onDelete(e)}>
                    <TrashIcon />
                  </div>
                </StyledCardDetailsPanelAction>
              </StyledCardDetailsPanelActionsSpace>
            </Form>
          </SpinSmall>
        </Collapse>
      </StyledCardDetailsPanelCard>
    </StyledCardDetailsPanel>
  );
}

CardDetailsPanel.propTypes = {
  cardDetails: PropTypes.shape({
    bankingData: PropTypes.object,
    cardData: PropTypes.object,
    loading: PropTypes.bool
  }),
  onDelete: PropTypes.func,
  onFormSubmit: PropTypes.func,
  onPause: PropTypes.func,
  subscription: PropTypes.object,
  isOpenAuthModal: PropTypes.bool
}

CardDetailsPanel.defaultProps = {
  isOpenAuthModal: false
}

const mapStateToProps = state => {
  const {isAdmin} = state.user;
  const {tags} = state.company;
  const cardLimits = cardsHelpers.getPaymentCardLimit(state);
  return {
    cardLimits,
    isAdmin,
    tags
  }
}

export default connect(mapStateToProps, null)(CardDetailsPanel);
